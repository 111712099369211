import {
  InteractableObject,
  ObjectInteractionMap,
  InteractionTypeEnum,
  DialogItem,
  LetterSentStatusEnum,
} from '../types/portfolio_types'
import {
  getLetterSentStatus,
  getAppleData,
  getMailmanCatFirstInteractionDone,
} from '../store/localstorageData'
import { just_exited_letter } from '../store/store'
import * as THREE from 'three'

const insufficient_apples: DialogItem = {
  id: 'insufficient_apples',
  text: `Uh, you don't have enough apples. Have you checked everywhere? Hehe`,
  included_expressions: {
    mailman_cat: 'standard cat bored',
  },
}

// When this dialog is fully typed, show a form to send the letter.
// Use the id to trigger it. After we have the result, trigger next (call determineBranch)
const lets_send: DialogItem = {
  id: 'send_letter',
  text: `Alright! let's send him a letter!`,
  included_expressions: {
    mailman_cat: 'standard cat joyful',
  },
  determineBranch: () => {
    if (just_exited_letter.value) return 'cancel'

    if (getLetterSentStatus() !== LetterSentStatusEnum.success) {
      return 'error'
    }

    return 'success'
  },
  branches: {
    success: [
      {
        id: 'sent_successfully',
        text: `Very well, I'll deliver it immediately! Keep in mind that if you need to get in touch with Ricardo ASAP, LinkedIn is your best bet.`,
      },
    ],
    error: [
      {
        id: 'try_sending_again',
        text: `Oh! Something went wrong, maybe it's your internet connection? Let's try again later.`,
      },
    ],
    cancel: [
      {
        id: 'cancel_letter',
        text: `No problem, we can send it later.`,
      },
    ],
  },
}

const see_ya: DialogItem = {
  id: 'see_ya',
  text: `See ya around!`,
}

const mailman_cat_interactions: ObjectInteractionMap = {
  mailman_cat_rig: {
    first_interaction: {
      id: 'first_interaction',
      type: InteractionTypeEnum.dialog,
      show_name: true,
      focus_on_start: true,
      onFinish: () => {
        localStorage.setItem('mailman_cat_first_interaction_done', 'true')
      },
      dialog: [
        {
          id: 'dialog1',
          text: 'Hello there! Oh, you must be Bianca, the guide of this forest. Yes yes yes, I remember you!',
          included_expressions: {
            mailman_cat: 'standard cat joyful',
          },
        },
        {
          id: 'dialog2',
          text: `Maybe you're wondering what a mailcat is doing here, but have you seen that giant mushroom house?`,
        },
        {
          id: 'dialog3',
          text: `YUUUUGE! Never thought I'd be assigned to a place like this.`,
        },
        {
          id: 'initial_send_letter_prompt',
          text: `I don't have any letters for you, but I can deliver one to Ricardo on your behalf for just 20 apples!`,
          prompt_options: [
            {
              id: 'send',
              text: `Let's send him a letter!`,
              determineBranch: () => {
                const applesData = getAppleData()

                if (applesData.collected === applesData.total) {
                  return 'continuation'
                } else {
                  return 'insufficient_apples'
                }
              },
              branches: {
                continuation: [lets_send],
                insufficient_apples: [insufficient_apples],
              },
            },
            {
              id: 'later',
              text: 'Maybe later',
              branches: {
                continuation: [see_ya],
              },
            },
          ],
        },
      ],
    },
    subsequent_interaction_letter_not_sent: {
      id: 'subsequent_interaction_letter_not_sent',
      type: InteractionTypeEnum.dialog,
      show_name: true,
      focus_on_start: true,
      dialog: [
        {
          id: 'dialog1',
          text: `Seen those tall trees over there? I'm pretty sure there's something special about them...`,
        },
        {
          id: 'subsequent_send_letter_prompt',
          text: `By the way, you can mail a letter to Ricardo for 20 apples if you feel like it.`,
          included_expressions: {
            mailman_cat: 'standard cat joyful',
          },
          prompt_options: [
            {
              id: 'send',
              text: `Let's send him a letter!`,
              determineBranch: () => {
                const applesData = getAppleData()

                if (applesData.collected === applesData.total) {
                  return 'continuation'
                } else {
                  return 'insufficient_apples'
                }
              },
              branches: {
                continuation: [lets_send],
                insufficient_apples: [insufficient_apples],
              },
            },
            {
              id: 'later',
              text: 'Maybe later',
              branches: {
                continuation: [see_ya],
              },
            },
          ],
        },
      ],
    },
    subsequent_interaction_letter_sent: {
      id: 'subsequent_interaction_letter_sent',
      type: InteractionTypeEnum.dialog,
      show_name: true,
      focus_on_start: true,
      dialog: [
        {
          id: 'dialog1',
          text: `Hello again! Don't worry, your letter will arrive safely, I'm a professional.`,
        },
        {
          id: 'other_questions_prompt',
          text: `Do you have any other questions?`,
          prompt_options: [
            {
              id: 'about_forest',
              text: 'Tell me about this place',
              branches: {
                continuation: [
                  {
                    id: 'about_forest_1',
                    text: `Various worlds are said to be connected to this forest, or so I have heard...`,
                  },
                  {
                    id: 'about_forest_2',
                    text: `I would try to find them if I were you! But you see, I've got some work to do.`,
                  },
                ],
              },
            },
            {
              id: 'about_job',
              text: `Do you enjoy your job?`,
              branches: {
                continuation: [
                  {
                    id: 'about_job_1',
                    text: `Yeah! I get to travel to different places delivering letters and packages. Who wouldn't love that?`,
                  },
                  {
                    id: 'about_job_2',
                    text: `I hope I don't get lost here though...How would I return to Madrid!`,
                  },
                ],
              },
            },
            {
              id: 'done',
              text: `No more questions for today!`,
              branches: {
                continuation: [
                  {
                    id: 'have_a_nice_one',
                    text: `Have a nice one!`,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
}

const interactable_mailman_cat_objects: { [key: string]: InteractableObject } = {
  mailman_cat_rig: {
    id: 'mailman_cat_rig',
    name: 'Mailman Cat',
    inspect_word: 'Talk',
    sensor_offset: new THREE.Vector3(0, 0, 0),
    sensor_size: [2.5, 2.5, 2.5],
    focus_on_contact: false,
    self_managed_location: true,
    interactions: mailman_cat_interactions.mailman_cat_rig,
    determineInteraction: () => {
      if (getLetterSentStatus() === LetterSentStatusEnum.success) {
        return 'subsequent_interaction_letter_sent'
      } else if (
        getLetterSentStatus() === LetterSentStatusEnum.error ||
        getMailmanCatFirstInteractionDone()
      ) {
        return 'subsequent_interaction_letter_not_sent'
      }

      return 'first_interaction'
    },
  },
}

export { interactable_mailman_cat_objects }
