import { RigidBody, CuboidCollider, CollisionPayload, RapierRigidBody, quat } from '@react-three/rapier'
import { SceneChild } from '../types/portfolio_types'
import * as THREE from 'three'
import { useRef, useState, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { Sphere } from '@react-three/drei'
import { getRandomColor } from '../helpers/helperFunctions'

type ButtonProps = {
  red_button: SceneChild
  button_base: SceneChild
  balls_box: SceneChild
}

const GROUP1 = 0x0001

const Button = ({ red_button, button_base, balls_box }: ButtonProps) => {
  // Performance tip: Do not use setState inside useFrame, use references instead as they don't trigger re-renders
  const buttonPressed = useRef(false)
  const btnBaseY = useRef(0)
  const button_ref = useRef<RapierRigidBody>(null)
  const box_ref = useRef<RapierRigidBody>(null)
  const [balls, setBalls] = useState<React.JSX.Element[]>([])

  const getPitchInDegrees = (quaternion: THREE.Quaternion) => {
    const euler = new THREE.Euler().setFromQuaternion(quaternion, 'XYZ')
    let pitch = THREE.MathUtils.radToDeg(euler.z)

    // Convert yaw to the desired range (otherwise it'd be -180 to 180)
    if (pitch < 0) {
      pitch += 360
    }

    return pitch
  }

  useFrame(() => {
    if (!button_ref.current) return

    const btnYPosition = button_ref.current.worldCom().y

    if (!btnBaseY.current && btnYPosition) {
      btnBaseY.current = btnYPosition
    }

    if (btnBaseY.current) {
      if (buttonPressed.current) {
        const offset = 0.2
        const buttonIsLowerThanItShould = btnYPosition < btnBaseY.current - offset

        if (buttonIsLowerThanItShould) {
          button_ref.current.setEnabledTranslations(false, false, false, true)
          button_ref.current.setLinvel({ x: 0, y: 0, z: 0 }, true)
        } else {
          button_ref.current.applyImpulse(new THREE.Vector3(0, -4, 0), true)
        }
      } else {
        button_ref.current.setEnabledTranslations(false, true, false, true)

        if (btnYPosition !== btnBaseY.current) {
          // For some reason the button gets stuck sometimes if we use .applyImpulse(new Vector3(0, 4, 0), true) here
          // I suspect it is because it gets stuck in the body right beneath it
          button_ref.current.setTranslation({ x: 0, y: 0, z: 0 }, true)
        }
      }
    }

    if (balls.length && box_ref.current) {
      const box_rotation = quat(box_ref.current.rotation())
      const box_pitch = getPitchInDegrees(box_rotation)
      const box_rotation_speed = 3
      const upside_down_box_degrees = 180
      const offset = 3

      const box_is_straight_up = box_pitch > 0 - offset && box_pitch < 0 + offset

      const box_is_upside_down =
        box_pitch > upside_down_box_degrees - offset && box_pitch < upside_down_box_degrees + offset

      if (buttonPressed.current) {
        if (box_is_upside_down) {
          box_ref.current.setEnabledRotations(false, false, false, true)
        } else {
          box_ref.current.setEnabledRotations(false, false, true, true)
          const rotation = new THREE.Euler(0, 0, -box_rotation_speed)
          box_ref.current.setAngvel(rotation, true)
        }
      } else {
        if (box_is_straight_up) {
          box_ref.current.setEnabledRotations(false, false, false, true)
        } else {
          box_ref.current.setEnabledRotations(false, false, true, true)
          const rotation = new THREE.Euler(0, 0, box_rotation_speed)
          box_ref.current.setAngvel(rotation, true)
        }
      }
    }
  })

  useEffect(() => {
    const balls_box_mesh = balls_box.object as THREE.Mesh

    balls_box_mesh.material = new THREE.MeshLambertMaterial({
      color: '#ecfcfd',
      opacity: 0.5,
      transparent: true,
    })

    const generateBalls = () => {
      if (balls.length) return balls

      const generated_balls: React.JSX.Element[] = []
      const box_bbox = new THREE.Box3().setFromObject(balls_box.object)
      const balls_number = 100
      const position_offset = new THREE.Vector3()
      const balls_per_row = 5
      const balls_per_layer = 20

      for (let i = 0; i < balls_number; i++) {
        const ball_position = new THREE.Vector3(box_bbox.min.x, box_bbox.min.y, box_bbox.max.z).add({
          x: 0.5,
          y: 2,
          z: -1,
        })
        position_offset.x += 0.7

        // Move the ball position to the next row (back all the way to the left with x, and forward with z)
        if (i && i % balls_per_row === 0) {
          position_offset.z -= 1
          position_offset.x -= 0.7 * balls_per_row
        }

        // Move the ball position to the next layer (above the previous ones)
        // No need move back in the x as that was done above
        if (i && i % balls_per_layer === 0) {
          // go backward to the first row, -1.2 was determined through trial and error
          position_offset.z += 1 * balls_per_row - 1.2
          // go up one layer
          position_offset.y += 1
        }

        ball_position.add(position_offset)
        const ball_color = getRandomColor()

        generated_balls.push(
          <RigidBody key={`ball-${i}`} position={ball_position} colliders="ball" type="dynamic">
            <Sphere args={[0.5, 12, 12]} frustumCulled={false}>
              <meshLambertMaterial color={ball_color} />
            </Sphere>
          </RigidBody>
        )
      }

      return generated_balls
    }

    setBalls(generateBalls())
  }, [balls_box, balls])

  const handleIntersectionEnter = (e: CollisionPayload) => {
    if (buttonPressed.current || !button_ref.current || e.colliderObject?.name !== 'bianca') return
    buttonPressed.current = true
  }

  const handleIntersectionExit = (e: CollisionPayload) => {
    if (!buttonPressed || !button_ref.current || e.colliderObject?.name !== 'bianca') return
    buttonPressed.current = false
  }

  return (
    <group>
      <RigidBody key={button_base.object.id} colliders={'hull'} type="fixed" name="button_base">
        {button_base.element}

        <CuboidCollider
          args={[1, 1, 1]}
          sensor
          onIntersectionEnter={handleIntersectionEnter}
          onIntersectionExit={handleIntersectionExit}
          position={new THREE.Vector3().copy(button_base.object.position).add({ x: 0, z: 0, y: 0 })}
        />
      </RigidBody>

      <RigidBody
        // Use a collision group to prevent the body from being influenced by other bodies, but still allow dynamic methods like applyImpulse
        collisionGroups={GROUP1}
        name="red_button"
        gravityScale={0}
        ref={button_ref}
        key={red_button.object.id}
        colliders={'trimesh'}
        type="dynamic"
        lockRotations
        friction={0}
      >
        {red_button.element}
      </RigidBody>

      <RigidBody
        name="balls_box"
        ref={box_ref}
        key={balls_box.object.id}
        colliders={'trimesh'}
        type="dynamic"
        gravityScale={0}
        lockTranslations
        enabledRotations={[false, false, false]}
      >
        {balls_box.element}
      </RigidBody>

      {balls}
    </group>
  )
}

export default Button
