import * as THREE from 'three'
import { useState, useEffect } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'

interface AppleSparklesProps {
  position: THREE.Vector3
}

const APPLE_SPARKLES_COLOR = '#da2525'

const AppleSparkles = (props: AppleSparklesProps) => {
  const [animationPlaying, setAnimationPlaying] = useState(false)
  const stars_model = useGLTF('/models/portfolio_forest/stars_animation.glb')
  const animations = useAnimations(stars_model.animations, stars_model.scene)

  useEffect(() => {
    stars_model.scene.children.forEach((child) => {
      const child_mesh = child as THREE.Mesh
      child_mesh.material = new THREE.MeshBasicMaterial({ color: APPLE_SPARKLES_COLOR })
    })

    const animation = animations.actions[animations.names[0]]!
    animation.play()
    setAnimationPlaying(true)

    const timeout = setTimeout(() => {
      setAnimationPlaying(false)
    }, 1100)

    return () => clearTimeout(timeout)
  }, [animations, stars_model])

  if (!animationPlaying) return null

  return (
    <group position={props.position} scale={0.8}>
      <primitive object={stars_model.scene} />
    </group>
  )
}

export default AppleSparkles

useGLTF.preload('/models/portfolio_forest/stars_animation.glb')
