import { InteractableObject, ObjectInteractionMap, InteractionTypeEnum } from '../types/portfolio_types'
import * as THREE from 'three'

const bianca_interactions: ObjectInteractionMap = {
  bianca_rig: {
    first_interaction: {
      id: 'first_interaction',
      type: InteractionTypeEnum.dialog,
      show_name: true,
      focus_on_start: true,
      dialog: [
        {
          id: 'dialog1',
          text: 'Hi! How are you liking this place?',
          included_expressions: {
            bianca: 'bianca happy',
          },
        },
        {
          id: 'dialog2',
          text: `Yes, it's actually a portfolio forest and I'm here to show it to you.`,
        },
        {
          id: 'initial_prompt',
          text: `Ask me anything! I'll be happy to answer.`,
          included_expressions: {
            bianca: 'bianca happy',
          },
          prompt_options: [
            {
              id: 'send',
              text: `What is your name?`,
              branches: {
                continuation: [
                  {
                    id: 'name_1',
                    text: 'I am Bianca the Raccoon, Special Portfolio Guide at your service!',
                    backToPreviousBranchOnFinish: true,
                    included_expressions: {
                      bianca: 'bianca naughty',
                    },
                  },
                ],
              },
            },
            {
              id: 'about_forest',
              text: 'How was this place made?',
              branches: {
                continuation: [
                  {
                    id: 'about_forest1',
                    text: `This is Ricardo's portfolio, a software developer with 3 and a half years of experience.`,
                  },
                  {
                    id: 'about_forest2',
                    text: `He is fully self-taught and has been searching for the definitive form of the frontend for years.`,
                  },
                  {
                    id: 'about_forest3',
                    text: `So he made all of these models in Blender, painted their textures and animated them.`,
                    included_expressions: {
                      bianca: 'bianca confused',
                    },
                  },
                  {
                    id: 'about_forest4',
                    text: `Then he coded this site with TypeScript and ThreeJS, putting it all together. Hope you like it!`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'no_more_questions',
              text: `That's all for now`,
              branches: {
                continuation: [
                  {
                    id: 'done',
                    text: `Alright, let's keep exploring!`,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
}

const interactable_bianca_objects: { [key: string]: InteractableObject } = {
  bianca_rig: {
    id: 'bianca_rig',
    name: 'Bianca',
    inspect_word: 'Talk',
    sensor_offset: new THREE.Vector3(0, 0, 0),
    sensor_size: [2.5, 2.5, 2.5],
    focus_on_contact: false,
    self_managed_location: true,
    rotate_in_place_when_facing: true,
    interactions: bianca_interactions.bianca_rig,
  },
}

export { interactable_bianca_objects }
