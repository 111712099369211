import mitt, { Emitter } from 'mitt'
import { PositionToSave } from '../types/portfolio_types'

type Events = {
  play_animation: string
  execute_slide_animation: void
  save_bianca_location: PositionToSave
  just_paid_apples: void
}

const emitter: Emitter<Events> = mitt<Events>()

export default emitter
