import { atom } from 'jotai'
import {
  InteractableObject,
  CurrentInteractionData,
  ObjectLocation,
  ApplesManagement,
  JobProject,
  BiancaLocation,
  ActiveMobileDirections,
  DialogItem,
} from '../types/portfolio_types'
import { Quaternion, Vector3 } from 'three'

// import { jobs_projects_objects } from '../interactable_data/jobs_projects_data'
// const job_project = jobs_projects_objects.real_estate

const DEFAULT_POSITION = new Vector3(0, 2, 0)
const DEFAULT_ROTATION = new Quaternion()

// ATOM STORE
const availableInteractableObjectAtom = atom<InteractableObject | null>(null)
const currentInteractionAtom = atom<CurrentInteractionData | null>(null)
const currentDialogItemAtom = atom<DialogItem | null>(null)
const currentJobProjectAtom = atom<JobProject | null>(null)
const insideHouseAtom = atom(false)

// This indicates if the user just used the browser navigation arrows to right-click and go into another world
// while being in one (e.g. from paperchat to train-workshop). This will allow us to set the default
// position for the new world
const justTraveledFromWorldToWorldAtom = atom(false)

const playerLocationAtom = atom<ObjectLocation | null>(null)
const currentInteractionLocationAtom = atom<ObjectLocation | null>(null)
const pickedApplesAtom = atom<ApplesManagement | null>(null)

const floorLoadedAtom = atom(false)
const biancaLoadedAtom = atom(false)
const loadingAtom = atom(true)
const loadingScreenClosedAtom = atom(false)
const gameStartedAtom = atom(false)
const showedKeyboardControlsHelpAtom = atom(false)

const biancaBeingAnimatedAtom = atom(false)
const animatingSlideAtom = atom(false)
const lastBiancaLocationAtom = atom<BiancaLocation>({
  position: DEFAULT_POSITION,
  rotation: DEFAULT_ROTATION,
})

const activeMobileDirectionsAtom = atom<ActiveMobileDirections>({
  mForward: false,
  mLeftward: false,
  mBackward: false,
  mRightward: false,
})

const activeMobileSprintAtom = atom(false)

const writingLetterAtom = atom(false)

// NOTE TO SELF:
// GLOBAL DATA THAT WE NEED TO ACCESS OUTSIDE REACT COMPONENTS (Jotai's store is a PITA to use outside react components)
// NEXT TIME MAKE SURE YOU PICK A STATE LIBRARY THAT IS SIMPLE (LIKE JOTAI) WHILE ALLOWING ACCESS FROM OUTSIDE
const just_exited_letter = { value: false }

// MORE NOTES:
// currentInteractionLocationAtom exists so that Bianca can rotate towards the object we're interacting with.

export {
  DEFAULT_ROTATION,
  DEFAULT_POSITION,
  availableInteractableObjectAtom,
  currentInteractionAtom,
  playerLocationAtom,
  currentInteractionLocationAtom,
  pickedApplesAtom,
  currentJobProjectAtom,
  floorLoadedAtom,
  lastBiancaLocationAtom,
  insideHouseAtom,
  biancaLoadedAtom,
  activeMobileDirectionsAtom,
  activeMobileSprintAtom,
  currentDialogItemAtom,
  justTraveledFromWorldToWorldAtom,
  biancaBeingAnimatedAtom,
  animatingSlideAtom,
  just_exited_letter,
  writingLetterAtom,
  loadingAtom,
  loadingScreenClosedAtom,
  gameStartedAtom,
  showedKeyboardControlsHelpAtom,
}
