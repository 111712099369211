import { DialogItem, InteractionItemDialog, JobProject, PromptOption } from '../../types/portfolio_types'
import { useState, useEffect, useCallback, useRef } from 'react'
import { isMobile, wait } from '../../helpers/helperFunctions'
import { interactLetter } from '../../helpers/config'
import styles from 'styles/components/Dialog.module.scss'
import { useAtom } from 'jotai'
import {
  currentJobProjectAtom,
  currentDialogItemAtom,
  writingLetterAtom,
  loadingAtom,
} from '../../store/store'
import { jobs_projects_objects } from '../../interactable_data/jobs_projects_data'
import emitter from '../../helpers/MittEmitter'
import Letter from './Letter'
import useTriggerLoading from '../../hooks/TriggerLoading'

const {
  dialog_container,
  small_dialog,
  name_tag,
  next_tag,
  next_letter,
  text_area,
  prompt_container,
  prompt_option_container,
  prompt_option: prompt_option_class,
  viewing_project_mode: viewing_project_mode_class,
  pointing_to,
} = styles

type DialogProps = {
  interaction_item: InteractionItemDialog
  inspecting_name?: string
  viewing_project_mode?: boolean
  onDialogEnd?: () => void
}

// Data structure to keep track of the current interaction.
type DialogReport = {
  id: string
  selected_prompt_id?: string
  selected_prompt_branch?: string
  determined_dialog_branch?: string
}

const DIALOG_INTERACT_LETTERS = [interactLetter, 'enter']

const Dialog = ({ interaction_item, inspecting_name, onDialogEnd, viewing_project_mode }: DialogProps) => {
  const [dialogsReport, setDialogsReport] = useState<DialogReport[]>([])
  const [currentDialogItem, setCurrentDialogItem] = useState<DialogItem | null>(null)
  const [currentText, setCurrentText] = useState('')
  const [pointingPromptOptionIndex, setPointingPromptOptionIndex] = useState(0)
  const [hoveringOption, setHoveringOption] = useState<PromptOption | null>(null)
  const [writingLetter, setWritingLetter] = useAtom(writingLetterAtom)
  const dialog_ref = useRef<HTMLDivElement>(null)
  const skip_typing = useRef(false)
  const [, setCurrentJobProject] = useAtom(currentJobProjectAtom)

  const [, setCurrentDialogItemObj] = useAtom(currentDialogItemAtom)
  const [loading] = useAtom(loadingAtom)

  const { triggerLoading } = useTriggerLoading()

  const getCurrentBranchAndDialog = useCallback(() => {
    let dialogItem = interaction_item.dialog[0]
    let dialogLevel = interaction_item.dialog

    for (const dialogReport of dialogsReport) {
      const matchingDialog = dialogLevel.find((dialogItem) => dialogItem.id === dialogReport.id)!

      dialogItem = matchingDialog
      const { selected_prompt_id, selected_prompt_branch, determined_dialog_branch } = dialogReport

      // Go into a selected prompt's branch
      if (dialogItem.prompt_options && selected_prompt_id && selected_prompt_branch) {
        const chosenPrompt = dialogItem.prompt_options.find(
          (promptOpt) => promptOpt.id === selected_prompt_id
        )!

        if (chosenPrompt.branches) {
          dialogLevel = chosenPrompt.branches[selected_prompt_branch]
        }
      }

      // Go into a regular dialog's branch if it has any
      if (!dialogItem.prompt_options && dialogItem.branches && determined_dialog_branch) {
        dialogLevel = dialogItem.branches[determined_dialog_branch]
      }
    }

    return { branch: dialogLevel, dialogItem }
  }, [interaction_item, dialogsReport])

  const exitDialog = useCallback(
    (final_dialog_reports?: DialogReport[]) => {
      dialog_ref.current?.classList.add('close_dialog')
      setTimeout(() => {
        if (final_dialog_reports) {
          const last_dialog_report = final_dialog_reports[final_dialog_reports.length - 1]

          // Trigger a project/job check
          if (last_dialog_report.id.startsWith('check_') && last_dialog_report.selected_prompt_id === 'yes') {
            const world_names_map: { [key: string]: JobProject } = {
              check_project_trainingmode: jobs_projects_objects.trainingmode,
              check_project_whoolso: jobs_projects_objects.whoolso,
              check_project_paperchat: jobs_projects_objects.paperchat,
              check_job_ctworks: jobs_projects_objects.train_workshop,
              check_job_realestate: jobs_projects_objects.real_estate,
              check_job_eci: jobs_projects_objects.department_store,
            }

            const job_project = world_names_map[last_dialog_report.id]

            emitter.emit('save_bianca_location', 'world')
            return triggerLoading(() => {
              setCurrentJobProject(job_project)
              onDialogEnd?.()
            })
          }
        }

        onDialogEnd?.()
      }, 300)
    },
    [onDialogEnd, setCurrentJobProject, triggerLoading]
  )

  const goToNextDialog = useCallback(
    (done_with_letter?: boolean) => {
      if (
        !currentDialogItem ||
        currentText !== currentDialogItem.text ||
        currentDialogItem.prompt_options ||
        (writingLetter && !done_with_letter)
      ) {
        return
      }

      if (currentDialogItem.backToPreviousBranchOnFinish) {
        return setDialogsReport([...dialogsReport.slice(0, dialogsReport.length - 1)])
      }

      // When the current dialog item branches, determine which branch we'll take
      if (!currentDialogItem.prompt_options && currentDialogItem.branches) {
        const branch_keys = Object.keys(currentDialogItem.branches)
        const branch_name = branch_keys.length === 1 ? branch_keys[0] : currentDialogItem.determineBranch!()
        const next_dialog_item = currentDialogItem.branches[branch_name][0]

        return setDialogsReport([
          ...dialogsReport.slice(0, dialogsReport.length - 1),
          {
            id: currentDialogItem.id,
            determined_dialog_branch: branch_name,
          },
          {
            id: next_dialog_item.id,
          },
        ])
      }

      // When the current dialog item doesn't have branches, go to the next one or exit the dialog
      const { branch } = getCurrentBranchAndDialog()
      const current_i = branch.findIndex((item) => item.id === currentDialogItem.id)
      const next_dialog = branch[current_i + 1]

      if (!next_dialog) {
        return exitDialog()
      }

      setDialogsReport([
        ...dialogsReport.slice(0, dialogsReport.length - 1),
        {
          id: next_dialog.id,
        },
      ])
    },
    [currentDialogItem, currentText, writingLetter, getCurrentBranchAndDialog, dialogsReport, exitDialog]
  )

  const selectPrompt = useCallback(
    (option: PromptOption) => {
      const last_dialog_report = dialogsReport[dialogsReport.length - 1]

      // When the selected prompt branches, determine in which branch we'll continue
      if (option.branches) {
        const branch_keys = Object.keys(option.branches)
        const branch_name = branch_keys.length === 1 ? branch_keys[0] : option.determineBranch!()
        const next_dialog_item = option.branches[branch_name][0]

        return setDialogsReport([
          ...dialogsReport.slice(0, dialogsReport.length - 1),
          {
            id: last_dialog_report.id,
            selected_prompt_id: option.id,
            selected_prompt_branch: branch_name,
          },
          {
            id: next_dialog_item.id,
          },
        ])
      }

      // When the selected prompt doesn't branch, exit the dialog
      const final_dialog_reports = [
        ...dialogsReport.slice(0, dialogsReport.length - 1),
        {
          id: last_dialog_report.id,
          selected_prompt_id: option.id,
        },
      ]

      setDialogsReport(final_dialog_reports)
      exitDialog(final_dialog_reports)
    },
    [dialogsReport, exitDialog]
  )

  const getNameTag = () => {
    if (inspecting_name && interaction_item.show_name) {
      return <div className={name_tag}>{inspecting_name}</div>
    }

    return null
  }

  const getNextTag = () => {
    if (currentText !== currentDialogItem?.text || currentDialogItem?.prompt_options) return null

    const getNextLetter = () => {
      if (isMobile()) return null
      return <span className={next_letter}>({interactLetter})</span>
    }

    return (
      <div onClick={() => goToNextDialog()} className={next_tag}>
        {getNextLetter()} Next
      </div>
    )
  }

  const getPrompt = () => {
    if (!currentDialogItem?.prompt_options || currentText !== currentDialogItem?.text) return null

    const getPointingHand = (i: number) => {
      if (i === pointingPromptOptionIndex) {
        return (
          <img
            className={`translate_left_right ${viewing_project_mode ? 'viewing_project_mode' : ''}`}
            src="/images/pointing_hand.png"
          />
        )
      }
    }

    const handleOptionMouseEnter = (option: PromptOption, i: number) => {
      setHoveringOption(option)
      setPointingPromptOptionIndex(i)
    }

    const promptOptions = () => {
      return currentDialogItem?.prompt_options?.map((prompt_option, i) => {
        return (
          <div key={prompt_option.id} className={prompt_option_container}>
            {getPointingHand(i)}

            <div
              onMouseEnter={() => handleOptionMouseEnter(prompt_option, i)}
              onMouseLeave={() => setHoveringOption(null)}
              className={`${prompt_option_class} ${i === pointingPromptOptionIndex ? pointing_to : ''}`}
              onClick={() => selectPrompt(prompt_option)}
            >
              {prompt_option.text}
            </div>
          </div>
        )
      })
    }

    return (
      <div className={`${prompt_container} ${currentDialogItem.small_dialog ? small_dialog : ''}`}>
        {promptOptions()}
      </div>
    )
  }

  const closeLetter = () => {
    setWritingLetter(false)
    goToNextDialog(true)
  }

  const getLetter = () => {
    if (!writingLetter) return

    return <Letter closeLetter={closeLetter} />
  }

  const skipTypingMessage = () => {
    if (!currentDialogItem || currentText === currentDialogItem.text) return
    setCurrentText(currentDialogItem.text)
    skip_typing.current = true
  }

  useEffect(() => {
    const handleInteract = (e: KeyboardEvent) => {
      if (DIALOG_INTERACT_LETTERS.includes(e.key.toLowerCase())) {
        if (currentDialogItem?.prompt_options) {
          const selected_prompt_option = currentDialogItem?.prompt_options[pointingPromptOptionIndex]
          selectPrompt(selected_prompt_option)
        } else {
          goToNextDialog()
        }
      }
    }

    const handleUpDown = (e: KeyboardEvent) => {
      if (!currentDialogItem?.prompt_options || hoveringOption) return

      if (e.key.toLowerCase() === 'w' || e.key.toLowerCase() === 'arrowup') {
        if (pointingPromptOptionIndex === 0) {
          return setPointingPromptOptionIndex(currentDialogItem.prompt_options.length - 1)
        }

        setPointingPromptOptionIndex((val) => val - 1)
      }

      if (e.key.toLowerCase() === 's' || e.key.toLowerCase() === 'arrowdown') {
        if (pointingPromptOptionIndex === currentDialogItem.prompt_options.length - 1) {
          return setPointingPromptOptionIndex(0)
        }

        setPointingPromptOptionIndex((val) => val + 1)
      }
    }

    document.addEventListener('keyup', handleInteract)
    document.addEventListener('keyup', handleUpDown)

    return () => {
      document.removeEventListener('keyup', handleInteract)
      document.removeEventListener('keyup', handleUpDown)
    }
  }, [goToNextDialog, currentDialogItem, pointingPromptOptionIndex, hoveringOption, selectPrompt])

  useEffect(() => {
    if (!dialogsReport.length) {
      return setDialogsReport([
        {
          id: interaction_item.dialog[0].id,
        },
      ])
    }

    const { dialogItem } = getCurrentBranchAndDialog()

    setCurrentText('')
    setCurrentDialogItem(dialogItem)
  }, [interaction_item, dialogsReport, getCurrentBranchAndDialog])

  useEffect(() => {
    if (!currentDialogItem || loading) return

    const typeMessage = async () => {
      const message_arr = currentDialogItem.text.split('')
      skip_typing.current = false

      const handleInteract = (e: KeyboardEvent) => {
        if (DIALOG_INTERACT_LETTERS.includes(e.key.toLowerCase())) {
          setCurrentText(currentDialogItem.text)
          skip_typing.current = true
        }
      }
      document.addEventListener('keyup', handleInteract)

      for await (const letter of message_arr) {
        if (skip_typing.current) break
        setCurrentText((val) => val + letter)
        await wait(30)
      }

      return document.removeEventListener('keyup', handleInteract)
    }

    typeMessage()
  }, [currentDialogItem, loading])

  useEffect(() => {
    if (!currentDialogItem) return
    setCurrentDialogItemObj(currentDialogItem)

    return () => {
      setCurrentDialogItemObj(null)
    }
  }, [currentDialogItem, setCurrentDialogItemObj])

  useEffect(() => {
    if (!currentDialogItem || currentText !== currentDialogItem.text) return

    if (currentDialogItem.id === 'send_letter') {
      setWritingLetter(true)
    }
  }, [currentDialogItem, currentText, setWritingLetter])

  return (
    <>
      <div
        ref={dialog_ref}
        className={`${dialog_container} 
      ${viewing_project_mode ? viewing_project_mode_class : ''} ${
          currentDialogItem?.small_dialog ? small_dialog : ''
        }`}
        onClick={skipTypingMessage}
      >
        {getNameTag()}
        <div className={text_area}>{currentText}</div>
        {getPrompt()}
        {getNextTag()}
      </div>

      {getLetter()}
    </>
  )
}

export default Dialog
