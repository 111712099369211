import { useEffect, useRef, useState, useCallback } from 'react'
import styles from 'styles/components/LoadingScreen.module.scss'
import { wait, isiOSMobile } from '../../helpers/helperFunctions'
import {
  biancaLoadedAtom,
  floorLoadedAtom,
  loadingAtom,
  currentJobProjectAtom,
  loadingScreenClosedAtom,
  gameStartedAtom,
} from '../../store/store'
import { worlds_urls } from '../../interactable_data/jobs_projects_data'
import { useAtom } from 'jotai'

const {
  loading_screen,
  start_screen,
  bg,
  open_screen,
  open_start_screen,
  loading_corner,
  animate_dot,
  start_screen_sign,
  top,
  title,
  subtitle,
  bottom,
  start_text,
  copyright_text,
  copyright_text_fadein,

  animate_close,
  animate_semi_open_to_fully_open,
  animate_open_screen,
  animate_open_start_screen,
  animate_close_start_screen,
} = styles

const LOADING_SCREEN_ANIMATION_LENGTH = 1200
const GRACE_PERIOD = 2500
const CAN_START_DELAY = 1500

interface LoadingScreenCSSClasses {
  base_class: string
  animation_class: string
}

interface AnimationPlayingData {
  state_name: string
  playing: boolean
}

const CSS_STATES: { [state_name: string]: LoadingScreenCSSClasses } = {
  loaded_start_screen_open: {
    base_class: `${loading_screen} ${open_start_screen}`,
    animation_class: animate_semi_open_to_fully_open,
  },
  start_screen: {
    base_class: `${loading_screen} ${start_screen}`,
    animation_class: animate_open_start_screen,
  },
  loading_no_animation: {
    base_class: loading_screen,
    animation_class: '',
  },
  loading_with_animation: {
    base_class: loading_screen,
    animation_class: animate_close,
  },
  loading_with_animation_from_start_screen: {
    base_class: loading_screen,
    animation_class: animate_close_start_screen,
  },
  loaded: {
    base_class: `${loading_screen} ${open_screen}`,
    animation_class: animate_open_screen,
  },
}

const LoadingScreen = () => {
  const [floorLoaded] = useAtom(floorLoadedAtom)
  const [biancaLoaded] = useAtom(biancaLoadedAtom)
  const [currentJobProject] = useAtom(currentJobProjectAtom)

  const [loading, setIsLoading] = useAtom(loadingAtom)
  const [, setLoadingScreenClosed] = useAtom(loadingScreenClosedAtom)
  const [gameStarted, setGameStarted] = useAtom(gameStartedAtom)

  const [showingStartScreen, setShowingStartScreen] = useState(false)

  const dot1_ref = useRef<HTMLSpanElement>(null)
  const dot2_ref = useRef<HTMLSpanElement>(null)
  const dot3_ref = useRef<HTMLSpanElement>(null)
  const can_start = useRef(false)

  const [loadedTimes, setLoadedTimes] = useState(0)
  const [loadingAnimationPlaying, setLoadingAnimationPlaying] = useState<AnimationPlayingData | null>(null)

  const times_loaded_with_animation = useRef(0)
  const awaiting_screen_open = useRef(false)

  useEffect(() => {
    const startDotsAnimation = async () => {
      dot1_ref.current?.classList.add(animate_dot)
      await wait(200)
      dot2_ref.current?.classList.add(animate_dot)
      await wait(200)
      dot3_ref.current?.classList.add(animate_dot)
    }

    const endDotsAnimation = async () => {
      dot1_ref.current?.classList.remove(animate_dot)
      dot2_ref.current?.classList.remove(animate_dot)
      dot3_ref.current?.classList.remove(animate_dot)
    }

    if (loading) {
      startDotsAnimation()
    }

    if (!loading) endDotsAnimation()
  }, [loading])

  // OPEN SCREEN
  useEffect(() => {
    const openScreen = async () => {
      awaiting_screen_open.current = true
      const world_url = worlds_urls[window.location.pathname]

      if (world_url || (!world_url && gameStarted)) {
        await wait(GRACE_PERIOD + LOADING_SCREEN_ANIMATION_LENGTH)

        setShowingStartScreen(false)
        setLoadingScreenClosed(false)
        setIsLoading(false)

        setLoadedTimes((val) => val + 1)
      } else {
        if (loadedTimes) {
          await wait(GRACE_PERIOD + LOADING_SCREEN_ANIMATION_LENGTH)
        }

        setIsLoading(false)
        setShowingStartScreen(true)
        setLoadingScreenClosed(false)

        // iOS mobile, at least on ipad (even its Chrome) has trouble rendering the mask property. It wouldn't show
        // the cicle in the loading screen if we don't re-render it here.
        // Toggling properties like visibility doesn't work, it has to be display.
        if (isiOSMobile()) {
          const bgElement = document.querySelector(`.${bg}`) as HTMLDivElement
          bgElement.style.display = 'none'
          await wait(5)
          bgElement.style.display = ''
        }

        await wait(CAN_START_DELAY)
        can_start.current = true
      }

      awaiting_screen_open.current = false
    }

    if (floorLoaded && loading && (biancaLoaded || currentJobProject) && !awaiting_screen_open.current) {
      openScreen()
    }
  }, [
    floorLoaded,
    biancaLoaded,
    setIsLoading,
    currentJobProject,
    loading,
    loadedTimes,
    gameStarted,
    setLoadingScreenClosed,
  ])

  const startGame = useCallback(() => {
    if (!showingStartScreen || !can_start.current || currentJobProject) return

    setShowingStartScreen(false)
    setGameStarted(true)
  }, [showingStartScreen, currentJobProject, setGameStarted])

  useEffect(() => {
    if (!currentJobProject) {
      setLoadedTimes(0)
    }
  }, [currentJobProject])

  useEffect(() => {
    document.addEventListener('keyup', startGame)
    return () => document.removeEventListener('keyup', startGame)
  }, [startGame])

  const getStartScreenText = () => {
    if (showingStartScreen || (!loading && !showingStartScreen)) {
      return (
        <div className={`${start_screen_sign}`}>
          <div className={top}>
            <div className={title}>Ricardo Sandez's</div>
            <div className={subtitle}>
              <span>Software</span>
              <span>Dev</span>
              <span>Portfolio</span>
            </div>
          </div>

          <div className={bottom}>
            <div className={start_text}>Press anywhere to start</div>
          </div>
        </div>
      )
    }

    return null
  }

  const getCopyrightText = () => {
    if (showingStartScreen || (!loading && !showingStartScreen)) {
      const current_year = new Date().getFullYear()

      return (
        <div className={`${copyright_text} ${showingStartScreen ? copyright_text_fadein : ''}`}>
          <img src="/images/ricardo_sandez.png" alt="" />

          <div>
            <p>© {current_year} Ricardo Sandez. All rights reserved.</p>
            <p>Designed and built by Ricardo Sandez, including 3D models.</p>
          </div>
        </div>
      )
    }

    return null
  }

  const getCSSClass = () => {
    let state_name = ''

    if (loading && !loadedTimes && !gameStarted && !showingStartScreen) {
      state_name = 'loading_no_animation'
    } else if (loading && showingStartScreen) {
      state_name = 'loading_with_animation_from_start_screen'
    } else if (loading) {
      state_name = 'loading_with_animation'

      // Same as above. iOS quirks, we need to do this the first time we're going to
      // display the loading screen with animation, otherwise the animation will cut mid-execution.
      const toggleElementForiOSMobile = async () => {
        const bgElement = document.querySelector(`.${bg}`) as HTMLDivElement
        bgElement.style.display = 'none'
        await wait(5)
        bgElement.style.display = ''
        times_loaded_with_animation.current += 1
      }

      if (!times_loaded_with_animation.current && isiOSMobile() && gameStarted) {
        toggleElementForiOSMobile()
      }
    } else if (!loading && !showingStartScreen && !loadedTimes) {
      state_name = 'loaded_start_screen_open'
    } else if (showingStartScreen) {
      state_name = 'start_screen'
    } else if (!loading) {
      state_name = 'loaded'
    }

    const { animation_class, base_class } = CSS_STATES[state_name]

    if (!loadingAnimationPlaying || loadingAnimationPlaying.state_name !== state_name) {
      setLoadingAnimationPlaying({ state_name, playing: !!animation_class })
      return `${base_class} ${animation_class}`
    }

    return loadingAnimationPlaying.playing ? `${base_class} ${animation_class}` : base_class
  }

  // Set that the current loading animation has finished playing after its duration has passed
  // They last 1.2s, this is defined in LoadingScreen.module.scss
  useEffect(() => {
    if (!loadingAnimationPlaying || !loadingAnimationPlaying.playing) return

    const timeout = setTimeout(() => {
      setLoadingAnimationPlaying({ ...loadingAnimationPlaying, playing: false })
    }, LOADING_SCREEN_ANIMATION_LENGTH + 50)

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [loadingAnimationPlaying])

  return (
    <div className={getCSSClass()} onClick={startGame}>
      {getStartScreenText()}
      {getCopyrightText()}

      <div className={bg}>
        <div className={`${loading_corner}`}>
          <div>
            Loading
            <span ref={dot1_ref}>.</span>
            <span ref={dot2_ref}>.</span>
            <span ref={dot3_ref}>.</span>
          </div>
          <img src="/images/bianca_loading.gif" alt="" />
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
