import { useEffect, useState, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { SceneChild } from '../../../types/portfolio_types'
import { floorLoadedAtom } from '../../../store/store'
import { useAtom } from 'jotai'
import * as THREE from 'three'
import { deepDispose } from '../../../helpers/deepDispose'

const WhoolsoTrophy = () => {
  const model = useGLTF('/models/visiting_worlds/whoolso_trophy.glb')
  const animations = useAnimations(model.animations, model.scene)
  const [sceneChildren, setSceneChildren] = useState<SceneChild[]>([])
  const [floorLoaded, setFloorLoaded] = useAtom(floorLoadedAtom)
  const scene_ref = useRef<THREE.Group>(null)

  useEffect(() => {
    window.history.pushState({}, '', '/worlds/whoolso')

    const processedSceneChildren = model.scene.children.map((child) => {
      return {
        object: child,
        element: <primitive object={child} key={child.id} />,
      }
    })

    setSceneChildren(processedSceneChildren)

    for (const materialKey of Object.keys(model.materials)) {
      const material = model.materials[materialKey] as THREE.MeshStandardMaterial

      if (material.map) {
        material.map.minFilter = THREE.LinearFilter
        material.map.magFilter = THREE.LinearFilter
      }
    }

    // PLAY ANIMATIONS
    animations.actions.bianca_looking_around!.play()
  }, [model, animations])

  useEffect(() => {
    const children_not_in_scene = sceneChildren
      .map((sceneChild) => sceneChild.object)
      .filter((obj) => !model.scene.children.find((child) => child.id === obj.id))

    model.scene.children.push(...children_not_in_scene)
  }, [model, sceneChildren])

  useEffect(() => {
    if (scene_ref.current && !floorLoaded) {
      setFloorLoaded(true)
    }
  })

  // DISPOSE OBJECTS ON UNMOUNT
  useEffect(() => {
    return () => {
      if (!floorLoaded || !sceneChildren.length) return
      sceneChildren.forEach((child) => deepDispose(child.object))
    }
  }, [sceneChildren, floorLoaded])

  const renderScene = () => {
    return sceneChildren.map((child) => child.element)
  }

  return <group ref={scene_ref}>{renderScene()}</group>
}

export default WhoolsoTrophy

// useGLTF.preload('/models/visiting_worlds/whoolso_trophy.glb')
