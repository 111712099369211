import { InteractableObject, ObjectInteractionMap, InteractionTypeEnum } from '../types/portfolio_types'
import { getAppleData } from '../store/localstorageData'
import * as THREE from 'three'

// NOTE: camera_position_offset is used in some objects that besides focusing (making the camera
// look at them), also change its position to end up with a better, more dramatic camera angle.
// It is determined manually by checking the camera position without any offset in the dat.gui, then
// changing it to the desired one. The offset to reach that desired number is camera_position_offset.
// We add it in Bianca.tsx's cameraFollowMe method.
const small_box_sensor: THREE.Vector3Tuple = [1.5, 1.5, 1]

const mushroom_house_object_interactions: ObjectInteractionMap = {
  desk: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'inspect_desk_1',
          text: `There's code on the monitor, and the notebook has a few to-do points written on it.`,
        },
        {
          id: 'inspect_desk_2',
          text: `Some character doodles decorate the pages as well.`,
        },
      ],
    },
  },
  bed: {
    inspect_not_having_picked_apple: {
      id: 'not_picked_apple',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'inspect_bed_1',
          text: `I wonder if there's something under this bed...`,
        },
        {
          id: 'inspect_bed_2',
          text: `Oh!`,
        },
      ],
    },
    inspect_having_picked_apple: {
      id: 'picked_apple',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'inspect_bed_1',
          text: `It's just a normal bed, and there are no more apples under it.`,
        },
      ],
    },
  },
  mysterious_door: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'check_project_paperchat',
          text: `It's locked.`,
        },
      ],
    },
  },
  house_exit: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.animation,
    },
  },
}

const interactable_mushroom_room_objects: { [key: string]: InteractableObject } = {
  desk: {
    id: 'desk',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 0, -2.5),
    sensor_size: [3, 1.5, 1],
    rotate_in_place_when_facing: true,
    focus_on_contact: false,
    interactions: mushroom_house_object_interactions.desk,
  },
  bed: {
    id: 'bed',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0.8, 0, 1.2),
    sensor_size: [2, 1.5, 2],
    on_inspect_animation: 'bianca_inspecting_rabbit_hole',
    focus_on_contact: false,
    interactions: mushroom_house_object_interactions.bed,
    determineInteraction: () => {
      const apple_data = getAppleData()

      if (apple_data.list.apple020) {
        return 'inspect_having_picked_apple'
      }

      return 'inspect_not_having_picked_apple'
    },
  },
  mysterious_door: {
    id: 'mysterious_door',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 4.5, 0),
    sensor_size: small_box_sensor,
    skip_setting_location: true,
    focus_on_contact: false,
    interactions: mushroom_house_object_interactions.mysterious_door,
  },
  house_exit: {
    id: 'house_exit',
    inspect_word: 'Exit',
    sensor_offset: new THREE.Vector3(2, 0, 0),
    sensor_size: small_box_sensor,
    skip_setting_location: true,
    focus_on_contact: false,
    interactions: mushroom_house_object_interactions.house_exit,
  },
}

export { interactable_mushroom_room_objects }
