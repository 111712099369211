import { useGLTF, useAnimations, Environment } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import emitter from '../helpers/MittEmitter'
import * as THREE from 'three'
import { SceneChild, JustPickedApplesMap, LetterSentStatusEnum } from '../types/portfolio_types'
import {
  RigidBody,
  CuboidCollider,
  CuboidArgs,
  RigidBodyAutoCollider,
  RigidBodyTypeString,
  CollisionPayload,
  RapierRigidBody,
} from '@react-three/rapier'
import Button from './Button'
import MailmanCat from './MailmanCat'
import { interactable_world_objects } from '../interactable_data/world_data'
import { interactable_mailman_cat_objects } from '../interactable_data/mailman_cat_data'
import { availableInteractableObjectAtom, pickedApplesAtom, floorLoadedAtom } from '../store/store'
import { getAppleData, getLetterSentStatus } from '../store/localstorageData'
import { useAtom } from 'jotai'
import { useFrame } from '@react-three/fiber'
import AppleSparkles from './AppleSparkles'
import { deepDispose } from '../helpers/deepDispose'

const objects_with_custom_components = [
  'apple',
  'red_button',
  'button_base',
  'mailman_cat_body',
  'mailman_cat_face',
  'mailman_cat_shirt',
  'mailman_cat_rig',
  'slide',
]
const exclusive_animations = [
  'jump_spring_animation',
  'mailman_cat_happy',
  'mailman_cat_idle',
  'mailman_cat_walk',
]

const Forest = () => {
  // Vite goes into the public folder by default
  const model = useGLTF('/models/portfolio_forest/portfolio_forest2.glb')
  const animations = useAnimations(model.animations, model.scene)
  const [sceneChildren, setSceneChildren] = useState<SceneChild[]>([])
  const [, setInteractableObject] = useAtom(availableInteractableObjectAtom)
  const [pickedApples, setPickedApples] = useAtom(pickedApplesAtom)
  const [floorLoaded, setFloorLoaded] = useAtom(floorLoadedAtom)
  const [justPickedApples, setJustPickedApples] = useState<JustPickedApplesMap>({})
  const box_apple_ref = useRef<RapierRigidBody | null>(null)
  const last_box_apple_position_ref = useRef<THREE.Vector3 | null>(null)
  const floor_physics_body = useRef<RapierRigidBody | null>(null)
  const letter_sent_status = useRef(getLetterSentStatus())

  useEffect(() => {
    const processedSceneChildren = model.scene.children.map((child) => {
      if (interactable_world_objects[child.name]) {
        interactable_world_objects[child.name].position = child.position
      }
      if (interactable_mailman_cat_objects[child.name]) {
        interactable_mailman_cat_objects[child.name].position = child.position
      }

      return {
        object: child,
        element: <primitive object={child} key={child.id} />,
        interactable: interactable_world_objects[child.name] || interactable_mailman_cat_objects[child.name],
      }
    })

    setSceneChildren(processedSceneChildren)

    // SET MIN FILTER IN THE TEXTURES THAT REQUIRE IT
    const forest_material = model.materials.forest_material as THREE.MeshStandardMaterial
    forest_material.map!.minFilter = THREE.LinearFilter

    // PLAY ANIMATIONS
    Object.keys(animations.actions)
      .filter((animation_name) => !exclusive_animations.includes(animation_name))
      .forEach((animation_name) => animations.actions[animation_name]!.play())
  }, [model, animations])

  useEffect(() => {
    if (!pickedApples) {
      const apple_data = getAppleData()
      setPickedApples(apple_data)
    }

    // This event is emitted from Letter.tsx, right after succesfully sending it.
    emitter.on('just_paid_apples', () => {
      const apple_data = getAppleData()
      letter_sent_status.current = getLetterSentStatus()
      setPickedApples(apple_data)
    })

    return () => emitter.off('just_paid_apples')
  }, [pickedApples, setPickedApples])

  useEffect(() => {
    localStorage.setItem('apples', JSON.stringify(pickedApples))
  }, [pickedApples])

  useEffect(() => {
    // Adding scene children to a primitive (or using them in the scene) removes them from scene.children, which will
    // prevent us from playing animations outside of our first useEffect (e.g. the jump spring)
    // on those removed children since useAnimations use model.scene as their root (second argument)
    const children_not_in_scene = sceneChildren
      .map((sceneChild) => sceneChild.object)
      .filter((obj) => !model.scene.children.find((child) => child.id === obj.id))

    model.scene.children.push(...children_not_in_scene)
  }, [model, sceneChildren])

  useEffect(() => {
    const handleAnimation = (animation_name: string) => {
      const animation = animations.actions[animation_name]!
      animation.loop = THREE.LoopOnce
      animation.reset().play()
    }

    emitter.on('play_animation', handleAnimation)

    return () => emitter.off('play_animation')
  }, [animations])

  useEffect(() => {
    if (floor_physics_body.current && !floorLoaded) {
      setFloorLoaded(true)
    }
  })

  // DISPOSE OBJECTS ON UNMOUNT
  useEffect(() => {
    return () => {
      if (!floorLoaded || !sceneChildren.length) return
      sceneChildren.forEach((child) => deepDispose(child.object))
    }
  }, [sceneChildren, floorLoaded])

  useFrame((state) => {
    const time = state.clock.getElapsedTime()
    const apple_spin_speed = 3

    sceneChildren
      .filter((child) => child.object.name.includes('apple'))
      .forEach((apple) => {
        if (apple.object.name.includes('box')) return

        const rotation = new THREE.Quaternion()
        rotation.setFromEuler(new THREE.Euler(0, time * apple_spin_speed, 0))
        apple.object.setRotationFromQuaternion(rotation)
      })
  })

  const renderButton = () => {
    const red_button = sceneChildren.find((child) => child.object.name === 'red_button')
    const button_base = sceneChildren.find((child) => child.object.name === 'button_base')
    const balls_box = sceneChildren.find((child) => child.object.name === 'balls_box')

    if (!red_button || !button_base || !balls_box) return

    return <Button button_base={button_base} red_button={red_button} balls_box={balls_box} />
  }

  const renderMailmanCat = () => {
    const mailman_cat_body = sceneChildren.find((child) => child.object.name === 'mailman_cat_body')
    const mailman_cat_face = sceneChildren.find((child) => child.object.name === 'mailman_cat_face')
    const mailman_cat_shirt = sceneChildren.find((child) => child.object.name === 'mailman_cat_shirt')
    const mailman_cat_rig = sceneChildren.find((child) => child.object.name === 'mailman_cat_rig')

    const mailman_cat_idle = animations.actions['mailman_cat_idle']!
    const mailman_cat_happy = animations.actions['mailman_cat_happy']!
    const mailman_cat_walk = animations.actions['mailman_cat_walk']!

    if (
      !mailman_cat_body ||
      !mailman_cat_face ||
      !mailman_cat_shirt ||
      !mailman_cat_rig ||
      !mailman_cat_idle ||
      !mailman_cat_happy ||
      !mailman_cat_walk
    )
      return

    return (
      <MailmanCat
        mailman_cat_body={mailman_cat_body}
        mailman_cat_face={mailman_cat_face}
        mailman_cat_shirt={mailman_cat_shirt}
        mailman_cat_rig={mailman_cat_rig}
        mailman_cat_animations={{ mailman_cat_idle, mailman_cat_happy, mailman_cat_walk }}
      />
    )
  }

  const renderSlide = () => {
    const slide_mesh = sceneChildren.find((child) => child.object.name === 'slide')
    const slide_platform = sceneChildren.find((child) => child.object.name === 'slide_platform')

    if (!slide_mesh || !slide_platform) return

    const triggerSlide = (e: CollisionPayload) => {
      if (e.colliderObject?.name !== 'bianca') return
      emitter.emit('execute_slide_animation')
    }

    const sensor_position = new THREE.Vector3(
      slide_mesh.object.position.x + 2,
      15,
      slide_mesh.object.position.z
    )

    return (
      <>
        <RigidBody
          name={slide_mesh.object.name}
          key={slide_mesh.object.id}
          colliders={'trimesh'}
          restitution={0.6}
          type={'fixed'}
        >
          {slide_mesh.element}

          <CuboidCollider
            sensor
            position={sensor_position}
            args={[1, 1, 1.5]}
            onIntersectionEnter={triggerSlide}
          />
        </RigidBody>

        <RigidBody
          name={slide_platform.object.name}
          key={slide_platform.object.id}
          colliders={'trimesh'}
          restitution={0.6}
          type={'fixed'}
        >
          {slide_platform.element}
        </RigidBody>
      </>
    )
  }

  const renderApples = () => {
    const apples = sceneChildren.filter((child) => child.object.name.includes('apple'))

    const getSparkles = (apple_name: string, position: THREE.Vector3) => {
      if (!justPickedApples || !justPickedApples[apple_name]) return null

      return <AppleSparkles position={position} />
    }

    const pickApple = (e: CollisionPayload, apple: SceneChild) => {
      if (e.colliderObject?.name !== 'bianca' || !pickedApples) return
      const apple_data = getAppleData()
      const updated_apples = { ...apple_data }

      if (updated_apples.list[apple.object.name] === false) {
        updated_apples.collected += 1
        updated_apples.list[apple.object.name] = true
        setPickedApples(updated_apples)

        const updated_just_picked_apples = { ...justPickedApples }
        updated_just_picked_apples[apple.object.name] = true
        setJustPickedApples(updated_just_picked_apples)

        // HANDLE THE APPLE IN THE BOX - The only apple that has physics instead of being static
        // Rapier throws an error if we don't set the box_apple_ref to null after picking a different
        // apple, and doing so prevents the sparkles from appearing, so we have to store the location separately
        if (apple.object.name.includes('box') && box_apple_ref.current) {
          const { x, y, z } = box_apple_ref.current.worldCom()
          last_box_apple_position_ref.current = new THREE.Vector3(x, y, z)
          box_apple_ref.current = null
        }
      }
    }

    return apples.map((apple) => {
      const must_render_apple =
        !pickedApples?.list[apple.object.name] && letter_sent_status.current !== LetterSentStatusEnum.success

      if (apple.object.name.includes('box')) {
        const position = last_box_apple_position_ref.current || apple.object.position

        return (
          <group key={apple.object.id}>
            {must_render_apple ? (
              <RigidBody
                name={apple.object.name}
                ref={box_apple_ref}
                key={apple.object.id}
                type="dynamic"
                colliders="ball"
                onCollisionEnter={(e) => pickApple(e, apple)}
              >
                {apple.element}
              </RigidBody>
            ) : null}

            {getSparkles(apple.object.name, position)}
          </group>
        )
      }

      return (
        <group key={apple.object.id}>
          {must_render_apple ? (
            <>
              <CuboidCollider
                sensor
                name={apple.object.name}
                position={apple.object.position}
                args={[0.7, 0.7, 0.7]}
                onIntersectionEnter={(e) => pickApple(e, apple)}
              />
              {apple.element}
            </>
          ) : null}

          {getSparkles(apple.object.name, apple.object.position)}
        </group>
      )
    })
  }

  const getInteractionSensor = (child: SceneChild) => {
    if (!child.interactable) return

    const sensor_offset = new THREE.Vector3().copy(child.interactable.sensor_offset)
    sensor_offset.applyQuaternion(child.object.quaternion)
    const mini_offset = 0.2

    // After many attemps, it wasn't possible to use bbox.max.z here.
    const position = new THREE.Vector3(
      child.object.position.x + mini_offset,
      1.5,
      child.object.position.z
    ).add(sensor_offset)

    const addInteractableObject = (e: CollisionPayload) => {
      if (!child.interactable || e.colliderObject?.name !== 'bianca') return
      setInteractableObject(child.interactable)
    }

    const removeInteractableObject = (e: CollisionPayload) => {
      if (e.colliderObject?.name !== 'bianca') return
      setInteractableObject(null)
    }

    return (
      <CuboidCollider
        position={position}
        sensor
        args={child.interactable.sensor_size}
        onIntersectionEnter={addInteractableObject}
        onIntersectionExit={removeInteractableObject}
        rotation={child.object.rotation}
      />
    )
  }

  const renderScene = () => {
    const objects_without_bodies_lax = [
      'sunflower',
      'pink_flowers',
      'red_flower',
      'cherry_blossom_tree_leaves',
      'big_pine_tree_leaves',
      'house_brick',
      'house_head',
      'house_chimney',
      'house_window',
      'house_door',
      'sign',
      'above',
      'shadows',
      'sandbox_sand',
    ]
    const objects_without_bodies_specific = ['jump_spring', 'spanish_flag', 'venezuelan_flag']

    // Meshes are rendered only if they are visible in the camera (this is basically what frustum culling means)
    // However, suddenly rendering some meshes causes a frame drop, it's better to always render them from the beginning
    const objects_without_frustum_culling: string[] = ['spanish_flag', 'venezuelan_flag']

    // The names with numbers (specifics) must go above the ones without them (generals) to avoid matching the general one first
    // The trees need a custom object because otherwise they create a long rectangle, like a cereal box, due to their branches.
    const objects_with_custom_collider: { [key: string]: CuboidArgs } = {
      big_pine_tree_trunk001: [3, 10, 2.5],
      big_pine_tree_trunk002: [3, 10, 2.5],
      big_pine_tree_trunk003: [3, 10, 2.5],
      cherry_blossom_tree_trunk001: [3, 12, 2],
      cherry_blossom_tree_trunk002: [3, 12, 2],
      cherry_blossom_tree_trunk003: [2.8, 12, 2.2],
      jump_spring_armature: [1.2, 1.3, 1.2],
    }

    const objects_collider_type: { [key: string]: RigidBodyAutoCollider } = {
      apple010: 'ball',
      personal_projects_arrow: 'hull',
      professional_exp_arrow: 'hull',
      sandbox_corners: 'trimesh',
      slide: 'trimesh',
      house_base: 'hull',
    }

    const objects_body_type: { [key: string]: RigidBodyTypeString } = {}

    const filteredScene = sceneChildren.filter(
      (obj) => !objects_with_custom_components.find((obj_name) => obj.object.name.includes(obj_name))
    )

    return filteredScene.map((child) => {
      if (objects_without_frustum_culling.includes(child.object.name)) {
        child.object.frustumCulled = false
      }

      if (
        objects_without_bodies_lax.find((objName) => child.object.name.toLowerCase().includes(objName)) ||
        objects_without_bodies_specific.find((objName) => child.object.name.toLowerCase() === objName)
      ) {
        return (
          <group key={child.object.id}>
            {child.element}
            {getInteractionSensor(child)}
          </group>
        )
      }

      for (const key of Object.keys(objects_with_custom_collider)) {
        if (child.object.name === key) {
          // NOTE: I have been trying to use the object's own position in the CuboidCollider, but I've encountered
          // some very confusing problems in the process:

          // 1) WHEN EXPORTING THE SCENE WITH THE POSITION (AND ROTATION) UNAPPLIED:
          // Objects keep their position and the scene is positioned correctly, but if I add the position to a group wrapping the primitive (<primitive>
          // does not allow the position prop), like this <group position={child.object.position}> then the objects
          // are all scattered around, wrongly positioned.

          // This is because the objects are already locally positioned within the imported scene, which is globally
          // positioned at [0, 0, 0], so, if we create a group and put our object in the primitive inside it, we'd be
          // making it a child of the group we've positioned with object's position, and therefore the object's position
          // would now be local..to its own position. In this case we should not apply the position again to any group
          // wrapping our object. The position has to be directly applied to the CuboidCollider we're interested in.

          // Note that even if their positions are unapplied, if the objects have an armature modifier their position will come zeroed,
          // it's their armature the one that will come with position data. If you needed to access the position of one of these meshes,
          // you'd have to get their armature, that's why it's so important to have a good naming convention.

          // 2) WHEN EXPORTING THE SCENE WITH THE POSITION (AND ROTATION) APPLIED:
          // All objects come with their position as { x: 0, y: 0, z: 0} and you will be able to apply it to
          // groups wrapping the objects without scattering the scene (e.g. <group position={child.position}>).
          // However, you won't be able to apply it to a CuboidCollider for example, because since the position is zeroed the collider will
          // end up in the center of the scene simply because its position is not applied relative to the imported scene (and there isn't a way to do that)
          // You could calculate the global position of the object, but since getWorldPosition works only after the mesh has been added to the scene,
          // you'd have to resort to stuff like this: https://discourse.threejs.org/t/why-all-meshes-has-the-same-matrixworld-values/35783/8

          // NOTE: applying location to deltas (the position is zeroed and the origin kept) does not work, it is equivalent to not applying it once exported.
          // SUMMARY: DO NOT APPLY ROTATION AND POSITION AND SIMPLY BE CAREFUL NOT TO USE THEM IN COMPONENTS THAT WRAP YOUR MESHES.

          return (
            <group key={child.object.id}>
              <CuboidCollider
                name={child.object.name}
                args={objects_with_custom_collider[key]}
                position={child.object.position}
                rotation={child.object.rotation}
              />
              {child.element}
              {getInteractionSensor(child)}
            </group>
          )
        }
      }

      return (
        <RigidBody
          ref={child.object.name === 'floor' ? floor_physics_body : null}
          name={child.object.name}
          key={child.object.id}
          colliders={objects_collider_type[child.object.name] || 'cuboid'}
          restitution={0.6}
          type={objects_body_type[child.object.name] || 'fixed'}
        >
          {child.element}
          {getInteractionSensor(child)}
        </RigidBody>
      )
    })
  }

  return (
    <>
      {renderScene()}
      {renderButton()}
      {renderMailmanCat()}
      {renderSlide()}
      {renderApples()}

      {/* Invisible wall in front of the scene */}
      <CuboidCollider args={[70, 20, 0.5]} position={[0, 1.5, 40]} />

      <Environment
        background
        resolution={1024}
        environmentIntensity={0.1}
        files={[
          '/environment_maps/forest_sky/forest_sky.png',
          '/environment_maps/forest_sky/forest_sky.png',
          '/environment_maps/forest_sky/forest_sky.png',
          '/environment_maps/forest_sky/forest_sky.png',
          '/environment_maps/forest_sky/forest_sky.png',
          '/environment_maps/forest_sky/forest_sky.png',
        ]}
      />
    </>
  )
}

export default Forest

useGLTF.preload('/models/portfolio_forest/portfolio_forest2.glb')
