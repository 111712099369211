import { useEffect, useState, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { SceneChild } from '../../../types/portfolio_types'
import * as THREE from 'three'
import useFaceManager from '../../../hooks/FaceManager'
import { floorLoadedAtom } from '../../../store/store'
import { useAtom } from 'jotai'
import { deepDispose } from '../../../helpers/deepDispose'

const TrainWorkshop = () => {
  const model = useGLTF('/models/visiting_worlds/train_workshop_scene.glb')
  const animations = useAnimations(model.animations, model.scene)
  const [sceneChildren, setSceneChildren] = useState<SceneChild[]>([])
  const [floorLoaded, setFloorLoaded] = useAtom(floorLoadedAtom)

  const lever_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)
  const blueprints_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)
  const painter_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)
  const tena_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)
  const blueprints_cat_expression_interval = useRef<NodeJS.Timeout | null>(null)
  const scene_ref = useRef<THREE.Group>(null)

  useFaceManager({
    id: 'lever_cat',
    face_name: 'standard cat',
    default_face: 'standard cat angry',
    face_material: lever_cat_face_material_ref.current,
    min_filter: THREE.NearestFilter,
  })

  const { changeCurrentFace: changeBlueprintsCatFace, current_base_face: blueprints_cat_current_base_face } =
    useFaceManager({
      id: 'blueprints_cat',
      face_name: 'standard cat',
      default_face: 'standard cat bored',
      face_material: blueprints_cat_face_material_ref.current,
      min_filter: THREE.NearestFilter,
    })

  useFaceManager({
    id: 'painter_cat',
    face_name: 'standard cat',
    default_face: 'standard cat bored',
    face_material: painter_cat_face_material_ref.current,
    min_filter: THREE.NearestFilter,
  })

  useFaceManager({
    id: 'tena_cat',
    face_name: 'tena cat',
    default_face: 'tena cat bored',
    face_material: tena_cat_face_material_ref.current,
    min_filter: THREE.NearestFilter,
  })

  useEffect(() => {
    window.history.pushState({}, '', '/worlds/train-workshop')

    const processedSceneChildren = model.scene.children.map((child) => {
      return {
        object: child,
        element: <primitive object={child} key={child.id} />,
      }
    })

    setSceneChildren(processedSceneChildren)

    lever_cat_face_material_ref.current = model.materials['lever cat face'] as THREE.MeshStandardMaterial

    blueprints_cat_face_material_ref.current = model.materials[
      'blueprints cat face'
    ] as THREE.MeshStandardMaterial

    painter_cat_face_material_ref.current = model.materials['painter cat face'] as THREE.MeshStandardMaterial
    tena_cat_face_material_ref.current = model.materials['tena cat face'] as THREE.MeshStandardMaterial

    for (const materialKey of Object.keys(model.materials)) {
      const material = model.materials[materialKey] as THREE.MeshStandardMaterial

      // If the materal has a texture (.map), set the minFilter to linear
      // to prevent jagged edges, visible gaps in seam lines, etc except in face textures, where
      // we need them sharp, so we apply NearestFilter in useFaceManager
      if (material.map) {
        if (material.transparent && material.name.toLowerCase().includes('face')) {
          // Fix z-fighting
          material.polygonOffset = true
          material.polygonOffsetFactor = -1
        } else {
          material.map.minFilter = THREE.LinearFilter
          material.map.magFilter = THREE.LinearFilter
        }
      }
    }

    // PLAY ANIMATIONS
    Object.keys(animations.actions).forEach((animation_name) => animations.actions[animation_name]!.play())
  }, [model, animations])

  useEffect(() => {
    const children_not_in_scene = sceneChildren
      .map((sceneChild) => sceneChild.object)
      .filter((obj) => !model.scene.children.find((child) => child.id === obj.id))

    model.scene.children.push(...children_not_in_scene)
  }, [model, sceneChildren])

  useEffect(() => {
    blueprints_cat_expression_interval.current = setInterval(() => {
      if (blueprints_cat_current_base_face.current.includes('bored')) {
        changeBlueprintsCatFace('standard cat angry')
      } else {
        changeBlueprintsCatFace('standard cat bored')
      }
    }, 3200)

    return () => {
      if (blueprints_cat_expression_interval.current) {
        clearInterval(blueprints_cat_expression_interval.current)
      }
    }
  }, [changeBlueprintsCatFace, blueprints_cat_current_base_face])

  useEffect(() => {
    if (scene_ref.current && !floorLoaded) {
      setFloorLoaded(true)
    }
  })

  // DISPOSE OBJECTS ON UNMOUNT
  useEffect(() => {
    return () => {
      if (!floorLoaded || !sceneChildren.length) return
      sceneChildren.forEach((child) => deepDispose(child.object))
    }
  }, [sceneChildren, floorLoaded])

  const renderScene = () => {
    return sceneChildren.map((child) => child.element)
  }

  return <group ref={scene_ref}>{renderScene()}</group>
}

export default TrainWorkshop

// Models like these add too much load time to the app initial load (the train workshop in particular is 5mb)
// So do not preload them
// useGLTF.preload('/models/visiting_worlds/train_workshop_scene.glb')
