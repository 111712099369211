import { getFirestore, addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getAuth, signInAnonymously } from 'firebase/auth'

const postLetter = async (text: string) => {
  const auth = getAuth()
  if (!auth.currentUser) await signInAnonymously(auth)

  const db = getFirestore()

  await addDoc(collection(db, 'letters'), {
    text,
    server_ts: serverTimestamp(),
  })
}

export { postLetter }
