import React, { useState, useRef, useEffect } from 'react'
import styles from 'styles/components/Letter.module.scss'
import { wait } from '../../helpers/helperFunctions'
import { just_exited_letter } from '../../store/store'
import { postLetter } from '../../firebase/firestoreDB'
import { LetterSentStatusEnum } from '../../types/portfolio_types'
import { ls_default_data } from '../../store/localstorageData'
import emitter from '../../helpers/MittEmitter'

const {
  letter_backdrop,
  letter,
  letter_center,
  letter_lines,
  horizontal_line,
  vertical_line,
  textarea2,
  send_button,
  sending_button,
  up,
  letter_exit_btn,
  exit_animation,
  fading_opacity_animation,
} = styles
const MAX_LENGTH = 185

interface LetterProps {
  closeLetter: () => void
}

const Letter = ({ closeLetter }: LetterProps) => {
  const [text, setText] = useState('')
  const [ghostText, setGhostText] = useState('')
  const textarea_ref = useRef<HTMLTextAreaElement | null>(null)
  const textarea_ghost_ref = useRef<HTMLTextAreaElement | null>(null)

  const [sendingLetter, setSendingLetter] = useState(false)
  const sending_dot_1 = useRef<HTMLSpanElement | null>(null)
  const sending_dot_2 = useRef<HTMLSpanElement | null>(null)
  const sending_dot_3 = useRef<HTMLSpanElement | null>(null)
  const modal_backdrop_ref = useRef<HTMLDivElement>(null)
  const letter_ref = useRef<HTMLDivElement>(null)

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textarea_ref.current
    const textarea_ghost = textarea_ghost_ref.current
    const textarea_ghost_dom = document.getElementById(textarea2)

    if (!textarea || !textarea_ghost || !textarea_ghost_dom) return

    setGhostText(e.target.value)
  }

  useEffect(() => {
    const textarea_ghost = textarea_ghost_ref.current
    if (!textarea_ghost) return

    if (textarea_ghost.scrollHeight <= textarea_ghost.clientHeight) {
      setText(ghostText)
    }
  }, [ghostText])

  const sendLetter = async () => {
    if (!text.trim().length) return
    setSendingLetter(true)

    try {
      await postLetter(text)
      localStorage.setItem('letter_sent_status', LetterSentStatusEnum.success)
      localStorage.setItem('apples', JSON.stringify(ls_default_data.apples))
      emitter.emit('just_paid_apples')
      exitLetter()
    } catch (error) {
      console.error(error)
      localStorage.setItem('letter_sent_status', LetterSentStatusEnum.error)
      exitLetter()
    }
  }

  const getSendButton = () => {
    if (sendingLetter) {
      return (
        <div className={`${send_button} ${sending_button}`}>
          Sending
          <span ref={sending_dot_1}>.</span>
          <span ref={sending_dot_2}>.</span>
          <span ref={sending_dot_3}>.</span>
        </div>
      )
    }

    return (
      <div className={send_button} onClick={sendLetter}>
        Send
      </div>
    )
  }

  useEffect(() => {
    const startDotsAnimation = async () => {
      sending_dot_1.current?.classList.add(up)
      await wait(200)
      sending_dot_2.current?.classList.add(up)
      await wait(200)
      sending_dot_3.current?.classList.add(up)
    }

    const endDotsAnimation = async () => {
      sending_dot_1.current?.classList.remove(up)
      sending_dot_2.current?.classList.remove(up)
      sending_dot_3.current?.classList.remove(up)
    }

    if (sendingLetter) startDotsAnimation()
    if (!sendingLetter) endDotsAnimation()
  }, [sendingLetter])

  useEffect(() => {
    just_exited_letter.value = false

    setTimeout(() => {
      textarea_ref.current?.focus()
    }, 500)
  }, [])

  const exitLetter = (exit_without_sending?: boolean) => {
    if (exit_without_sending) {
      just_exited_letter.value = true
    }

    if (modal_backdrop_ref.current && letter_ref.current) {
      letter_ref.current.classList.add(exit_animation)

      setTimeout(() => {
        modal_backdrop_ref.current?.classList.add('no_opacity')
      }, 200)

      setTimeout(() => {
        closeLetter()
      }, 500)
    } else {
      closeLetter()
    }
  }

  const getLetterExitButton = () => {
    if (sendingLetter) return

    return (
      <div className={letter_exit_btn} onClick={() => exitLetter(true)}>
        <img src={`/images/ui-icons/close.png`} alt="close icon" />
      </div>
    )
  }

  return (
    <div className={letter_backdrop} ref={modal_backdrop_ref}>
      <div className={letter} ref={letter_ref}>
        {getLetterExitButton()}

        <div className={letter_center}>
          <div className={letter_lines}>
            <div className={vertical_line}></div>

            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
            <div className={horizontal_line}></div>
          </div>

          <textarea
            ref={textarea_ref}
            placeholder="Write your letter here"
            maxLength={MAX_LENGTH}
            value={text}
            onChange={handleTextChange}
            disabled={sendingLetter}
            className={`${sendingLetter ? fading_opacity_animation : ''}`}
          />

          <textarea
            readOnly
            id={textarea2}
            ref={textarea_ghost_ref}
            maxLength={MAX_LENGTH}
            value={ghostText}
            onChange={handleTextChange}
          />
        </div>

        {getSendButton()}
      </div>
    </div>
  )
}

export default Letter
