import { Object3D, BufferGeometry, Material, Texture, ShaderMaterial, Mesh } from 'three'

/**
 * Traverse material or array of materials and all nested textures
 * executing there respective callback
 *
 * @param material          Three js Material or array of material
 * @param materialCallback  Material callback
 * @param textureCallback   Texture callback
 */
const traverseMaterialsTextures = (
  material: Material | Material[],
  materialCallback?: (material: Material) => void,
  textureCallback?: (texture: Texture) => void
) => {
  const traverseMaterial = (mat: Material) => {
    if (materialCallback) materialCallback(mat)

    if (!textureCallback) return

    Object.values(mat)
      .filter((value) => value instanceof Texture)
      .forEach((texture) => {
        textureCallback(texture)
      })

    if ((mat as ShaderMaterial).uniforms)
      Object.values((mat as ShaderMaterial).uniforms)
        .filter(({ value }) => value instanceof Texture)
        .forEach(({ value }) => textureCallback(value))
  }

  if (Array.isArray(material)) {
    material.forEach((mat) => traverseMaterial(mat))
  } else traverseMaterial(material)
}

/**
 * Dispose of all Object3D`s nested Geometries, Materials and Textures
 *
 * @param object  Object3D, BufferGeometry, Material or Texture
 * @param disposeMedia If set to true will dispose of the texture image or video element, default false
 */
const deepDispose = (object: Object3D | BufferGeometry | Material | Texture) => {
  const dispose = (object: BufferGeometry | Material | Texture) => object.dispose()

  const disposeObject = (object: Object3D | BufferGeometry | Material | Texture) => {
    const objMesh = object as Mesh
    if (objMesh.geometry) dispose(objMesh.geometry)
    if (objMesh.material) traverseMaterialsTextures(objMesh.material, dispose, dispose)
  }

  if (object instanceof BufferGeometry || object instanceof Texture) return dispose(object)
  if (object instanceof Material) return traverseMaterialsTextures(object, dispose, dispose)

  disposeObject(object)

  if (object.traverse) object.traverse(disposeObject)
}

export { deepDispose }
