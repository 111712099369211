import { useEffect, useRef } from 'react'
import { Environment, CameraControls } from '@react-three/drei'
import TrainWorkshop from './models/visiting_worlds/TrainWorkshop'
import RealEstate from './models/visiting_worlds/RealEstate'
import DepartmentStore from './models/visiting_worlds/DepartmentStore'
import TrainingMode from './models/visiting_worlds/TrainingMode'
import WhoolsoTrophy from './models/visiting_worlds/WhoolsoTrophy'
import Paperchat from './models/visiting_worlds/Paperchat'
import { VisitingWorldNameEnum } from '../types/portfolio_types'
import {
  floorLoadedAtom,
  currentJobProjectAtom,
  lastBiancaLocationAtom,
  insideHouseAtom,
  justTraveledFromWorldToWorldAtom,
  DEFAULT_POSITION,
} from '../store/store'
import { useAtom } from 'jotai'
import * as THREE from 'three'

const VisitingWorld = () => {
  const [currentJobProject] = useAtom(currentJobProjectAtom)
  const [floorLoaded] = useAtom(floorLoadedAtom)
  const [insideHouse, setInsideHouse] = useAtom(insideHouseAtom)
  const [justTraveledFromWorldToWorld] = useAtom(justTraveledFromWorldToWorldAtom)
  const [lastBiancaLocation, setLastBiancaLocation] = useAtom(lastBiancaLocationAtom)
  const controls_ref = useRef<CameraControls>(null)

  const getWorldToRender = () => {
    const world_name = currentJobProject?.world

    if (world_name === VisitingWorldNameEnum.train_workshop) {
      return <TrainWorkshop />
    }

    if (world_name === VisitingWorldNameEnum.real_estate) {
      return <RealEstate />
    }

    if (world_name === VisitingWorldNameEnum.department_store) {
      return <DepartmentStore />
    }

    if (world_name === VisitingWorldNameEnum.trainingmode) {
      return <TrainingMode />
    }

    if (world_name === VisitingWorldNameEnum.whoolso) {
      return <WhoolsoTrophy />
    }

    if (world_name === VisitingWorldNameEnum.paperchat) {
      return <Paperchat />
    }
  }

  const getControls = () => {
    if (!currentJobProject) return

    const { controls_min_distance, controls_max_distance } = currentJobProject

    return (
      <CameraControls
        ref={controls_ref}
        maxDistance={controls_max_distance}
        minDistance={controls_min_distance}
      ></CameraControls>
    )
  }

  useEffect(() => {
    if (!currentJobProject || !controls_ref.current) return

    // Prevent truck/pan by setting a boundary, it seems to be the only way to do it with camera controls
    const num = currentJobProject?.controls_bbox_size || 40
    const min = new THREE.Vector3(-num, -num, -num)
    const max = new THREE.Vector3(num, num, num)
    const boundary_box = new THREE.Box3(min, max)

    controls_ref.current.setBoundary(boundary_box)

    if (currentJobProject.controls_camera_position) {
      if (currentJobProject.controls_camera_position_mobile) {
        const IS_MOBILE = window.innerWidth < 600

        if (IS_MOBILE) {
          controls_ref.current.setPosition(...currentJobProject.controls_camera_position_mobile)
          return
        }
      }

      controls_ref.current.setPosition(...currentJobProject.controls_camera_position)
    }
  }, [currentJobProject])

  useEffect(() => {
    if (!floorLoaded || !currentJobProject) return

    const last_location_is_default = lastBiancaLocation.position.equals(DEFAULT_POSITION)

    if (
      (last_location_is_default || insideHouse || justTraveledFromWorldToWorld) &&
      currentJobProject.default_exit_location
    ) {
      setInsideHouse(false)
      setLastBiancaLocation(currentJobProject.default_exit_location)
    }
  }, [
    currentJobProject,
    floorLoaded,
    lastBiancaLocation,
    setLastBiancaLocation,
    insideHouse,
    setInsideHouse,
    justTraveledFromWorldToWorld,
  ])

  return (
    <>
      <group name="visiting_world">
        {getControls()}
        {getWorldToRender()}
      </group>

      <Environment
        // Without the "background" prop, environment will only illuminate the scene
        background
        resolution={512}
        files={[
          '/environment_maps/tree_bark/px.png',
          '/environment_maps/tree_bark/nx.png',
          '/environment_maps/tree_bark/py.png',
          '/environment_maps/tree_bark/ny.png',
          '/environment_maps/tree_bark/pz.png',
          '/environment_maps/tree_bark/nz.png',
        ]}
      />
    </>
  )
}

export default VisitingWorld
