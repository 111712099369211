import { JobProject, InteractionTypeEnum, VisitingWorldNameEnum } from '../types/portfolio_types'
import * as THREE from 'three'

const DEFAULT_EXIT_Y = 1.5

// NOTE1: To determine an appropiate camera position for each scene (which is subjective), you can test it
// by logging controls_ref.current?.getPosition(pos) in VisitingWorld.tsx in an interval

// NOTE2: default_exit_location's rotation was determined manually by doing console.log(quat(bianca_physics_body.current?.rotation()))
const jobs_projects_objects: { [key: string]: JobProject } = {
  train_workshop: {
    id: 'train_workshop',
    name: 'CTworks',
    world: VisitingWorldNameEnum.train_workshop,
    type: 'job',
    job_start: 'Feb. 2021',
    job_end: 'Jan. 2023',
    video_name: 'semapp',
    video_title: `Semapp, the train workshop management app`,
    icon_name: 'semapp.png',
    controls_max_distance: 100,
    controls_min_distance: 30,
    controls_camera_position: [33, 13, 70],
    links: [
      {
        id: 'semapp_webapp',
        platform_type: 'webapp',
        url: 'https://semapp.cometius.app/',
        private: true,
        published: true,
      },
      {
        id: 'semapp_app',
        platform_type: 'android',
        url: '',
        private: true,
        published: true,
      },
    ],
    dialog: {
      id: 'train_workshop_dialog',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'dialog1',
          text: `This was Ricardo's first job. CTworks is a company specialized in the digital transformation of industrial processes.`,
        },
        {
          id: 'dialog2',
          text: `The full name is Custom Technology Works, by the way!`,
          included_expressions: {
            bianca_canvas: 'bianca happy',
          },
        },
        {
          id: 'dialog3',
          text: `The main product was Semapp, a hybrid web and Android app designed to aid in the process of train wagon refurbishment.`,
        },
        {
          id: 'dialog_questions_prompt',
          text: `Full stack development was done! What else would you like to know?`,
          prompt_options: [
            {
              id: 'about_semapp',
              text: 'Tell me more about Semapp',
              branches: {
                continuation: [
                  {
                    id: 'about_semapp_1',
                    text: `The goal was to create a workshop management application for the rail industry in Germany and France.`,
                  },
                  {
                    id: 'about_semapp_2',
                    text: `Users would record wagon data through the entire process of refurbishment, from the initial inspection to the final bill.`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                  },
                  {
                    id: 'about_semapp_3',
                    text: `You could download this data as PDF and Excel docs that the app could generate. KPIs of the process were calculated too.`,
                  },
                  {
                    id: 'about_semapp_4',
                    text: `In the Android app, operators could take pictures which were available and downloadable as PDFs in the web version.`,
                  },
                  {
                    id: 'about_semapp_5',
                    text: `Oh! And there was a big focus on offline mode since the app would be used outside in some rural areas with poor connection.`,
                    included_expressions: {
                      bianca_canvas: 'bianca confused',
                    },
                  },
                  {
                    id: 'about_semapp_6',
                    text: `We'd store changes and pictures locally, and then upload them as soon as the user's connection was restored.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_other_apps',
              text: `What other apps did he work on here?`,
              branches: {
                continuation: [
                  {
                    id: 'other_apps_1',
                    text: `While 80% of the time was invested into Semapp, a couple of other smaller apps were made.`,
                  },
                  {
                    id: 'other_apps_2',
                    text: `We worked on an Android app to send data from a smartphone to a Raspberry Pi through Bluetooth serial port.`,
                  },
                  {
                    id: 'other_apps_3',
                    text: `We needed to send settings info to a Raspberry Pi inside vending machines.`,
                  },
                  {
                    id: 'other_apps_4',
                    text: `There was also another Vue SPA where vending machine owners could log in to manage each machine.`,
                  },
                  {
                    id: 'other_apps_5',
                    text: `Ricardo brewed two npm packages while he was here.`,
                    included_expressions: {
                      bianca_canvas: 'bianca naughty',
                    },
                  },
                  {
                    id: 'other_apps_6',
                    text: `The first one, cordova-plugin-mediadrmid would fetch us a unique, non-resettable id in Android devices as an IMEI alternative.`,
                  },
                  {
                    id: 'other_apps_7',
                    text: `The second one, cordova-plugin-bluetooth-serial-a10, was a fork of a Capacitor plugin for serial communication over Bluetooth.`,
                  },
                  {
                    id: 'other_apps_8',
                    text: `Some permission changes were necessary for it to work on Android 10.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_stack',
              text: `Tell me about the tech stack`,
              branches: {
                continuation: [
                  {
                    id: 'about_stack_1',
                    text: `We used Vuejs with Quasar Framework in the frontend, Nodejs with Express and MongoDB in the backend.`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                  },
                  {
                    id: 'about_stack_2',
                    text: `We leveraged Capacitor as an SDK to make mobile apps. No TypeScript was used in any project though.`,
                  },
                  {
                    id: 'about_stack_3',
                    text: `Authentication was done with JWT. For deployments, Firebase and Google Cloud products proved handy.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    default_exit_location: {
      position: new THREE.Vector3(16, DEFAULT_EXIT_Y, -4.27),
      rotation: new THREE.Quaternion(0, 0.05, 0, -0.99),
    },
  },
  real_estate: {
    id: 'real_estate',
    name: 'MeiT',
    world: VisitingWorldNameEnum.real_estate,
    type: 'job',
    job_start: 'Jan. 2023',
    job_end: 'Sept. 2023',
    video_name: 'meit',
    video_title: 'MeiT, the modern renting platform',
    icon_name: 'meit.png',
    controls_max_distance: 150,
    controls_min_distance: 40,
    controls_camera_position: [45, 42, 105],
    controls_bbox_size: 20,
    links: [
      {
        id: 'meit_webapp',
        platform_type: 'webapp',
        url: 'https://app.meit.es/',
        private: false,
        published: true,
      },
      {
        id: 'meit_api',
        platform_type: 'api',
        url: '',
        private: true,
        published: true,
      },
    ],
    dialog: {
      id: 'real_estate_dialog',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'dialog1',
          text: `MeiT was a startup helping people find long term rentals, matching the landlord's and tenant preferences.`,
        },
        {
          id: 'dialog2',
          text: `They allowed full apartment, room and student residence advertisements.`,
        },
        {
          id: 'dialog_questions_prompt',
          text: `Ricardo also did full stack development here. Got any questions?`,
          included_expressions: {
            bianca_canvas: 'bianca happy',
          },
          prompt_options: [
            {
              id: 'about_realestate',
              text: 'Tell me more about MeiT',
              branches: {
                continuation: [
                  {
                    id: 'about_realestate_1',
                    text: `The site let owners post their properties through a carefully crafted ad upload process.`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                  },
                  {
                    id: 'about_realestate_2',
                    text: `Postings were displayed in maps while being able to search using a plethora of filters like square meters and whatnot.`,
                  },
                  {
                    id: 'about_realestate_3',
                    text: `There was also a private backoffice app where MeiT employees could provide support and verify the users' identities.`,
                  },
                  {
                    id: 'about_realestate_4',
                    text: `This was done through documents they'd upload from their profile.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'highlight_achievement',
              text: `Favorite contribution here?`,
              branches: {
                continuation: [
                  {
                    id: 'favorite_contribution_1',
                    text: `Ricardo gathered geojson data of Spain to implement MongoDB geospatial queries based on the Spanish political divisions.`,
                  },
                  {
                    id: 'favorite_contribution_2',
                    text: `This allowed us to determine the Autonomous Community, Province, Municipality, District and Neighborhood of a property.`,
                  },
                  {
                    id: 'favorite_contribution_3',
                    text: `We only needed to know its address and then we'd have all of that data immediately!`,
                    included_expressions: {
                      bianca_canvas: 'bianca confused',
                    },
                  },
                  {
                    id: 'favorite_contribution_4',
                    text: `Lots of style improvements were also done in the frontend, taking inspiration from AirBnB.`,
                  },
                  {
                    id: 'favorite_contribution_5',
                    text: `And finally, many filters were added to the backoffice site to quickly find useful client info.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_stack',
              text: `Tell me about the tech stack`,
              branches: {
                continuation: [
                  {
                    id: 'about_stack_1',
                    text: `TypeScript with React in the frontend, and with Node in the backend!`,
                  },
                  {
                    id: 'about_stack_2',
                    text: `Well, TS was used everywhere. It was a big monorepo following hexagonal architecture with the NX Framework.`,
                  },
                  {
                    id: 'about_stack_3',
                    text: `MongoDB as a database!`,
                    included_expressions: {
                      bianca_canvas: 'bianca naughty',
                    },
                  },
                  {
                    id: 'about_stack_4',
                    text: `Lovely stack, right? SCSS was the CSS preprocessor of choice and Heroku and Firebase were used for cloud-related things.`,
                  },
                  {
                    id: 'about_stack_5',
                    text: `Finally, logs were handled with Loggly and payments processed with the Stripe API.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    default_exit_location: {
      position: new THREE.Vector3(36.7, DEFAULT_EXIT_Y, -3.5),
      rotation: new THREE.Quaternion(0, 0.04, 0, -0.99),
    },
  },
  department_store: {
    id: 'department_store',
    name: 'El Corte Inglés',
    world: VisitingWorldNameEnum.department_store,
    type: 'job',
    job_start: 'Sept. 2023',
    job_end: 'Present',
    video_name: 'eci',
    video_title: 'The El Corte Inglés website, powered by its own CMS',
    icon_name: 'eci.png',
    controls_max_distance: 60,
    controls_min_distance: 15,
    controls_camera_position: [10, 14, 30],
    controls_camera_position_mobile: [20, 16, 35],
    controls_bbox_size: 20,
    links: [
      {
        id: 'eci_webapp',
        platform_type: 'webapp',
        url: 'https://elcorteingles.es/',
        private: false,
        published: true,
      },
      {
        id: 'eci_api',
        platform_type: 'api',
        url: '',
        private: true,
        published: true,
      },
    ],
    dialog: {
      id: 'department_store_dialog',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'dialog1',
          text: `Did you know that El Corte Ingles is the biggest department store group in Europe?`,
        },
        {
          id: 'dialog2',
          text: `Ricardo joined the team that develops and maintains their in-house Content Management System (CMS).`,
          included_expressions: {
            bianca_canvas: 'bianca happy',
          },
        },
        {
          id: 'dialog_questions_prompt',
          text: `The CMS allows setting content that appears in all digital products of El Corte Ingles. Questions time!`,
          prompt_options: [
            {
              id: 'about_cms',
              text: 'Tell me more about that CMS',
              branches: {
                continuation: [
                  {
                    id: 'about_cms_1',
                    text: `It's an app that allows non-technical users to set content in a system of rows and columns.`,
                  },
                  {
                    id: 'about_cms_2',
                    text: `This way you decide what deals, products and other stuff will appear in the ECI website, app, supermarket, and other sites.`,
                    included_expressions: {
                      bianca_canvas: 'bianca naughty',
                    },
                  },
                  {
                    id: 'about_cms_3',
                    text: `The app is essentially a custom-made CMS for El Corte Ingles with many extra features.`,
                  },
                  {
                    id: 'about_cms_4',
                    text: `As it's a confidential tool, the video you can see here is the public ECI website.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_bug',
              text: 'Any hard bugs you solved?',
              branches: {
                continuation: [
                  {
                    id: 'about_bug_1',
                    text: `Nearing the release of an important feature, an endpoint started failing randomly.`,
                    included_expressions: {
                      bianca_canvas: 'bianca confused',
                    },
                  },
                  {
                    id: 'about_bug_2',
                    text: `Sometimes it'd respond with an empty payload, sometimes it'd work correctly.`,
                    included_expressions: {
                      bianca_canvas: 'bianca confused',
                    },
                  },
                  {
                    id: 'about_bug_3',
                    text: `Turns out there was an Express middleware function that was being unexpectedly called.`,
                  },
                  {
                    id: 'about_bug_4',
                    text: `It was because a controller function was using a method of the 'async' library while that controller itself was async.`,
                  },
                  {
                    id: 'about_bug_5',
                    text: `The method of the 'async' library was calling the middleware as soon as the controller's promise resolved.`,
                  },
                  {
                    id: 'about_bug_6',
                    text: `And at that point is when we sent the response. This created a sort of race-condition between Express' res.send and the async library.`,
                  },
                  {
                    id: 'about_bug_7',
                    text: `To find this, Ricardo had to dig deep into the boot files of the app.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_stack',
              text: `Tell me about the tech stack`,
              branches: {
                continuation: [
                  {
                    id: 'about_stack_1',
                    text: `The work was done in a very large monorepo with many services. It is massive, believe me.`,
                  },
                  {
                    id: 'about_stack_2',
                    text: `TypeScript and Angular were used in the frontend, less being the CSS preprocessor this time.`,
                  },
                  {
                    id: 'about_stack_3',
                    text: `The backend used Nodejs with Express and MongoDB with Mongo's driver, that is, without an ODM library like Mongoose.`,
                  },
                  {
                    id: 'about_stack_4',
                    text: `The complexity of this project is huge due to its longevity and the countless hands that have molded it.`,
                    included_expressions: {
                      bianca_canvas: 'bianca confused',
                    },
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    default_exit_location: {
      position: new THREE.Vector3(49.1, DEFAULT_EXIT_Y, -2),
      rotation: new THREE.Quaternion(0, -0.5, 0, 0.85),
    },
  },
  paperchat: {
    id: 'paperchat',
    name: 'Paperchat.net',
    world: VisitingWorldNameEnum.paperchat,
    type: 'personal project',
    release_date: 'November 13th, 2022',
    video_name: 'paperchat',
    video_title: 'Paperchat, the live drawing retro app',
    icon_name: 'paperchat.png',
    github_link: 'https://github.com/Lietsaki/paperchat',
    controls_max_distance: 16,
    controls_min_distance: 6,
    controls_camera_position: [-4.8, 2, 7],
    controls_bbox_size: 3,
    links: [
      {
        id: 'paperchat_webapp',
        platform_type: 'webapp',
        url: 'https://paperchat.net',
        private: false,
        published: true,
      },
      {
        id: 'paperchat_app',
        platform_type: 'android',
        url: 'https://play.google.com/store/apps/details?id=net.paperchat.app',
        private: false,
        published: true,
      },
    ],
    dialog: {
      id: 'paperchat',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'dialog1',
          text: `Paperchat is an online spiritual successor of Nintendo's Pictochat 2004 application.`,
        },
        {
          id: 'dialog_questions_prompt',
          text: `It allows you to chat and draw in rooms of up to 16 people. Ask me anything!`,
          included_expressions: {
            bianca_canvas: 'bianca happy',
          },
          prompt_options: [
            {
              id: 'about_app',
              text: 'Tell me about the app',
              branches: {
                continuation: [
                  {
                    id: 'about_app_1',
                    text: `The UI replicates Pictochat, an app that came with the 2004 Nintendo DS, sound effects included!`,
                    included_expressions: {
                      bianca_canvas: 'bianca naughty',
                    },
                  },
                  {
                    id: 'about_app_2',
                    text: `It allows real-time drawing and chatting on a canvas in chat rooms of up to 16 people.`,
                  },
                  {
                    id: 'about_app_3',
                    text: `Letters can be drag and dropped into the canvas to allow for greater creative expression, hehe.`,
                  },
                  {
                    id: 'about_app_4',
                    text: `If you're shy, private rooms also exist. Just share the code with anyone to let them join!`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                  },
                  {
                    id: 'about_app_5',
                    text: `Even if you don't have internet connection, you can draw in an offline room.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_stack',
              text: `Tell me about the tech stack`,
              branches: {
                continuation: [
                  {
                    id: 'about_stack_1',
                    text: `This one is open source! You can check the code on Github. Well, almost all of it.`,
                    included_expressions: {
                      bianca_canvas: 'bianca naughty',
                    },
                  },
                  {
                    id: 'about_stack_2',
                    text: `The frontend uses with TypeScript and React, and an Android app was generated with Capacitor.`,
                  },
                  {
                    id: 'about_stack_3',
                    text: `The backend was made with Firebase to avoid maintaining a Node server, and the related files are private.`,
                  },
                  {
                    id: 'about_stack_4',
                    text: `Oh! by the way, the Android app sends push notifications if you're in a room and the app is in the background.`,
                  },
                  {
                    id: 'about_stack_5',
                    text: `The sound is the same Pictochat had in the Nintendo DS!`,
                    included_expressions: {
                      bianca_canvas: 'bianca confused',
                    },
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    default_exit_location: {
      position: new THREE.Vector3(-45.7, DEFAULT_EXIT_Y, -2.7),
      rotation: new THREE.Quaternion(0, 0.4, 0, 0.91),
    },
  },
  trainingmode: {
    id: 'trainingmode',
    name: 'Trainingmode.pro',
    world: VisitingWorldNameEnum.trainingmode,
    type: 'personal project',
    release_date: 'March 23rd, 2022',
    video_name: 'trainingmode-v2',
    video_title: 'TrainingMode, the live fighting game combo simulator app',
    icon_name: 'training-mode.png',
    controls_max_distance: 6,
    controls_min_distance: 2,
    controls_camera_position: [1, 3.2, 2.7],
    controls_bbox_size: 1,
    links: [
      {
        id: 'trainingmode_webapp',
        platform_type: 'webapp',
        url: 'https://trainingmode.pro/',
        private: false,
        published: true,
      },
      {
        id: 'trainingmode_app',
        platform_type: 'android',
        url: 'https://play.google.com/store/apps/details?id=app.trainingmode.pro',
        private: false,
        published: true,
      },
    ],
    dialog: {
      id: 'trainingmode',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'dialog1',
          text: `This app is designed to help fighting game beginners understand how Numpad Notation works.`,
        },
        {
          id: 'dialog2',
          text: `It is Ricardo's first project and was initially released in 2020 with Vue2 and JavaScript.`,
        },
        {
          id: 'dialog3',
          text: `And then it got overhauled in 2022 with Vue3 and TypeScript!`,
          included_expressions: {
            bianca_canvas: 'bianca happy',
          },
        },
        {
          id: 'dialog_questions_prompt',
          text: `It simulates an arcade machine and includes a set of minigames. Let's talk about it! I'm all ears.`,
          prompt_options: [
            {
              id: 'about_app',
              text: 'Tell me about the app',
              branches: {
                continuation: [
                  {
                    id: 'about_app_1',
                    text: `Trainingmode.pro is a hybrid web and Android app that simulates the buttons commonly used in japanese fighting games.`,
                  },
                  {
                    id: 'about_app_2',
                    text: `It lets you use a virtual joystick and button inputs to create combos from various games.`,
                  },
                  {
                    id: 'about_app_3',
                    text: `Besides the homepage playground, there's a combo challenge mode where you have to input a series of commands in sequence.`,
                    included_expressions: {
                      bianca_canvas: 'bianca confused',
                    },
                  },
                  {
                    id: 'about_app_4',
                    text: `When a challenge is completed with 3 stars, you unlock digital collectibles in the form of human-made pixel art cats.`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_stack',
              text: `Tell me about the tech stack`,
              branches: {
                continuation: [
                  {
                    id: 'about_stack_1',
                    text: `Personal projects are made to learn technologies in depth. This one uses Vue3 with TypeScript.`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                  },
                  {
                    id: 'about_stack_2',
                    text: `Version 1 was made in 2020 with Vue2 and JavaScript, simple enough.`,
                  },
                  {
                    id: 'about_stack_3',
                    text: `And then updated in 2022 with Vue3 and TypeScript using the Quasar Framework!`,
                    included_expressions: {
                      bianca_canvas: 'bianca naughty',
                    },
                  },
                  {
                    id: 'about_stack_4',
                    text: `The Android app is compiled with Capacitor and was released with the v2. Oh, forgot to mention the styles use SCSS.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    default_exit_location: {
      position: new THREE.Vector3(-17.2, DEFAULT_EXIT_Y, -2.5),
      rotation: new THREE.Quaternion(0, 0, 0, 0.99),
    },
  },
  whoolso: {
    id: 'whoolso',
    name: 'Whoolso',
    world: VisitingWorldNameEnum.whoolso,
    type: 'personal project',
    release_date: 'Unreleased',
    video_name: 'whoolso-webapp',
    video_title: 'Whoolso, the amusement park-themed social network',
    icon_name: 'whoolso.png',
    controls_max_distance: 16,
    controls_min_distance: 9,
    controls_camera_position: [2, 2.6, 11.5],
    controls_camera_position_mobile: [8, 6.6, 10],
    controls_bbox_size: 1.5,
    links: [
      {
        id: 'whoolso_webapp',
        platform_type: 'webapp',
        url: '',
        private: false,
        published: false,
      },
      {
        id: 'whoolso_api',
        platform_type: 'api',
        url: 'https://documenter.getpostman.com/view/7311265/TVsuD7qa',
        private: false,
        published: true,
      },
    ],
    dialog: {
      id: 'whoolso',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'dialog1',
          text: `Whoolso is a fictitious social network made to learn Full Stack development with JavaScript, primarily backend.`,
        },
        {
          id: 'dialog2',
          text: `Even though it's unreleased, you can visit the API's Postman collection to see what it'd be like.`,
        },
        {
          id: 'dialog3',
          text: `The endpoints actually worked until Heroku shut down its free tier.`,
          included_expressions: {
            bianca_canvas: 'bianca confused',
          },
        },
        {
          id: 'dialog_questions_prompt',
          text: `Ricardo stopped working on the project when he got his first job in February 2021. Ask me about it!`,
          prompt_options: [
            {
              id: 'about_app',
              text: 'What was the idea?',
              branches: {
                continuation: [
                  {
                    id: 'about_app_1',
                    text: `The idea was to make a reddit-twitter hybrid following an amusement park theme.`,
                  },
                  {
                    id: 'about_app_2',
                    text: `There was a heavy focus on 'gamification', users could unlock trophies after performing certain seemingly hidden actions.`,
                  },
                  {
                    id: 'about_app_3',
                    text: `You could also award prizes to other users' posts. These trophies and prizes had their own SVG icons and descriptions.`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                  },
                  {
                    id: 'about_app_4',
                    text: `The app allowed you to create communities around different topics, with admins and moderator roles included.`,
                  },
                  {
                    id: 'about_app_5',
                    text: `Besides joining communities, you could also follow other users. And of course, a big focus was put on styles and responsiveness.`,
                  },
                  {
                    id: 'about_app_6',
                    text: `Maybe this comes across as a surprise, but I am a character originally designed for Whoolso.`,
                    included_expressions: {
                      bianca_canvas: 'bianca happy',
                    },
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
            {
              id: 'about_stack',
              text: `Tell me about the tech stack`,
              branches: {
                continuation: [
                  {
                    id: 'about_stack_1',
                    text: `The stack was fairly simple: JavaScript, Node.js with Express and Mongoose, and Vue2. A classic REST API following MVC.`,
                  },
                  {
                    id: 'about_stack_2',
                    text: `Socket.io was used to send notifications in real-time from Node.js, pretty cool, don't you think?`,
                    included_expressions: {
                      bianca_canvas: 'bianca naughty',
                    },
                  },
                  {
                    id: 'about_stack_3',
                    text: `If you ask me about third party integrations, I'd say the Paypal, Sendgrid and AWS S3 APIs were involved.`,
                    backToPreviousBranchOnFinish: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    default_exit_location: {
      position: new THREE.Vector3(-32.4, DEFAULT_EXIT_Y, -2.5),
      rotation: new THREE.Quaternion(0, 0.03, 0, 0.99),
    },
  },
}

const worlds_urls: { [key: string]: JobProject } = {
  '/worlds/training-mode': jobs_projects_objects.trainingmode,
  '/worlds/whoolso': jobs_projects_objects.whoolso,
  '/worlds/paperchat': jobs_projects_objects.paperchat,
  '/worlds/train-workshop': jobs_projects_objects.train_workshop,
  '/worlds/real-estate': jobs_projects_objects.real_estate,
  '/worlds/department-store': jobs_projects_objects.department_store,
}

export { jobs_projects_objects, worlds_urls }
