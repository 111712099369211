import { useState, useRef } from 'react'
import styles from 'styles/components/VisitingWorldUI.module.scss'
import { currentJobProjectAtom } from '../../store/store'
import { useAtom } from 'jotai'
import Dialog from './Dialog'
import useTriggerLoading from '../../hooks/TriggerLoading'

const {
  visiting_world_ui,
  world_data_area,
  world_data_bg,
  world_name_tag,
  job_project_type_tag,
  job_project_date,
  personal_project_offset,
  world_data_left,
  project_type_and_date,
  world_data_right,
  watch_video_btn,
  world_links_bg,
  platform_link_item,
  disabled,
  mobile_info_toggle,
  aux_buttons,
  rotated_arrow,
  toggled_mobile_data,

  modal_backdrop,
  video_modal,
  modal_title_row,
  close_modal_btn,
  modal_body,
  modal_title,
  exit_button,
  exit_modal,
  cancel_btn,
  confirm_btn,
} = styles

const VisitingWorldUI = () => {
  const [currentJobProject, setCurrentJobProject] = useAtom(currentJobProjectAtom)
  const [videoModalActive, setVideoModalActive] = useState(false)
  const [confirmExitModalActive, setConfirmExitModalActive] = useState(false)

  const modal_backdrop_ref = useRef<HTMLDivElement>(null)
  const world_data_area_ref = useRef<HTMLDivElement>(null)
  const toggle_arrow_ref = useRef<HTMLImageElement>(null)

  const { triggerLoading } = useTriggerLoading()

  const backToForest = () => {
    window.history.pushState({}, '', '/')
    triggerLoading(() => setCurrentJobProject(null))
  }

  const getVideoModal = () => {
    if (!videoModalActive || !currentJobProject) return null

    const closeModal = () => {
      if (modal_backdrop_ref) {
        modal_backdrop_ref.current?.classList.add('no_opacity')

        setTimeout(() => {
          setVideoModalActive(false)
        }, 220)
      } else {
        setVideoModalActive(false)
      }
    }

    return (
      <div ref={modal_backdrop_ref} className={modal_backdrop}>
        <div className={video_modal}>
          <div className={modal_title_row}>
            <div className={modal_title}>{currentJobProject.video_title}</div>

            <div className={close_modal_btn} onClick={closeModal}>
              <img src={`/images/ui-icons/close.png`} alt="close icon" />
            </div>
          </div>

          <div className={modal_body}>
            <video muted controls autoPlay loop>
              <source src={`/videos/${currentJobProject.video_name}.mp4`} type="video/mp4" />
              <source src={`/videos/${currentJobProject.video_name}-webm.webm`} type="video/webm" />
              <div>Your browser doesn't support this video. Please use a new one.</div>
            </video>
          </div>
        </div>
      </div>
    )
  }

  const getExitModal = () => {
    if (!confirmExitModalActive || !currentJobProject) return null

    const closeModal = () => {
      if (modal_backdrop_ref) {
        modal_backdrop_ref.current?.classList.add('no_opacity')

        setTimeout(() => {
          setConfirmExitModalActive(false)
        }, 220)
      } else {
        setConfirmExitModalActive(false)
      }
    }

    return (
      <div ref={modal_backdrop_ref} className={modal_backdrop}>
        <div className={exit_modal}>
          <div className={modal_title_row}>
            <div className={modal_title}>Are you sure you want to go back to the forest?</div>
          </div>

          <div className={modal_body}>
            <div className={confirm_btn} onClick={backToForest}>
              Yes, let's go back
            </div>
            <div className={cancel_btn} onClick={closeModal}>
              No, let's stay here
            </div>
          </div>
        </div>
      </div>
    )
  }

  const toggleMobileInfo = () => {
    if (!currentJobProject || !world_data_area_ref.current || !toggle_arrow_ref.current) {
      return
    }

    const div = world_data_area_ref.current
    const toggle_arrow = toggle_arrow_ref.current

    toggle_arrow.classList.toggle(rotated_arrow)
    div.classList.toggle(toggled_mobile_data)
  }

  const getWorldData = () => {
    if (!currentJobProject) return null
    const is_project = currentJobProject.type === 'personal project'

    const getDate = () => {
      const { job_start, job_end, release_date } = currentJobProject

      if (job_start && job_end) {
        return (
          <div className={job_project_date}>
            <div>From {job_start}</div>
            <div>To {job_end}</div>
          </div>
        )
      }

      if (release_date) {
        return (
          <div className={`${job_project_date} ${personal_project_offset}`}>
            <div>Release date</div>
            <div>{release_date}</div>
          </div>
        )
      }
    }

    const getLinks = () => {
      const links = currentJobProject.links.map((link) => {
        let link_type = link.platform_type
        if (link_type === 'npm') link_type += ' package'
        if (link_type === 'android') link_type += ' app'

        if (link.private) {
          return (
            <div key={link.id} className={`${platform_link_item} ${disabled}`}>
              <img src={`/images/ui-icons/${link.platform_type}.png`} alt={`${link.platform_type} icon`} />
              <div>{link_type} is private</div>
            </div>
          )
        }

        if (!link.published) {
          return (
            <div key={link.id} className={platform_link_item}>
              <img src={`/images/ui-icons/${link.platform_type}.png`} alt={`${link.platform_type} icon`} />
              <div>{link_type} not published</div>
            </div>
          )
        }

        let message = ''
        if (link.platform_type === 'android') message = 'Get android app'
        if (link.platform_type === 'api') message = 'Check api docs'
        if (link.platform_type === 'webapp') message = 'Visit webapp'
        if (link.platform_type === 'npm') message = 'Check npm package'

        const icon = link.platform_type === 'android' ? 'play_store' : link.platform_type

        return (
          <a key={link.id} href={link.url} target="_blank" className={platform_link_item}>
            <img src={`/images/ui-icons/${icon}.png`} alt={`${icon} icon`} />
            <div>{message}</div>
          </a>
        )
      })

      if (currentJobProject.github_link) {
        links.push(
          <a
            key={`${currentJobProject.name}_github`}
            href={currentJobProject.github_link}
            target="_blank"
            className={platform_link_item}
          >
            <img src={`/images/ui-icons/github.png`} alt="github icon" />
            <div>Fork me on Github!</div>
          </a>
        )
      }

      return links
    }

    return (
      <div className={world_data_area} ref={world_data_area_ref}>
        <div className={world_data_bg}>
          <div className={world_data_left}>
            <div className={world_name_tag}>{currentJobProject.name}</div>

            <div className={project_type_and_date}>
              <div className={`${job_project_type_tag} ${is_project ? personal_project_offset : ''}`}>
                {currentJobProject.type}
              </div>
              {getDate()}
            </div>
          </div>

          <div className={world_data_right}>
            <div className={watch_video_btn} onClick={() => setVideoModalActive(true)}>
              Watch video
            </div>
          </div>
        </div>

        <div className={`${world_links_bg} ${is_project ? personal_project_offset : ''}`}>{getLinks()}</div>

        <div className={aux_buttons}>
          <div className={mobile_info_toggle} title="Toggle info" onClick={() => toggleMobileInfo()}>
            <img ref={toggle_arrow_ref} src={`/images/ui-icons/arrow_down.png`} alt="arrow down icon" />
          </div>

          <div className={exit_button} title="Exit" onClick={() => setConfirmExitModalActive(true)}>
            <img src={`/images/ui-icons/exit.png`} alt="exit icon" />
          </div>
        </div>
      </div>
    )
  }

  const getDialog = () => {
    if (!currentJobProject) return null

    return <Dialog viewing_project_mode inspecting_name={''} interaction_item={currentJobProject.dialog} />
  }

  return (
    <div className={visiting_world_ui}>
      {getWorldData()}

      {getDialog()}
      {getVideoModal()}
      {getExitModal()}
    </div>
  )
}

export default VisitingWorldUI
