import { ApplesManagement, LetterSentStatusEnum } from '../types/portfolio_types'

type LSKey = 'letter_sent_status' | 'mailman_cat_first_interaction_done' | 'apples'

interface LocalStorageDefaultData {
  mailman_cat_first_interaction_done: boolean
  letter_sent_status: LetterSentStatusEnum
  apples: ApplesManagement
}

const total_apples_number = 20

const generateApplesList = () => {
  const apple_list: { [key: string]: boolean } = {}

  for (let i = 1; i <= total_apples_number; i++) {
    const prefix = 'apple'
    const numberString = i.toString()
    let processedNumberString = numberString

    if (numberString.length === 1) processedNumberString = `00${processedNumberString}`
    if (numberString.length === 2) processedNumberString = `0${processedNumberString}`
    if (i === 10) processedNumberString = `${processedNumberString}box`

    const name = prefix + processedNumberString
    apple_list[name] = false
  }

  return apple_list
}

const ls_default_data: LocalStorageDefaultData = {
  letter_sent_status: LetterSentStatusEnum.not_started,
  mailman_cat_first_interaction_done: false,
  apples: {
    total: total_apples_number,
    collected: 0,
    list: generateApplesList(),
  },
}

const setDefaultValuesLS = () => {
  localStorage.setItem('letter_sent_status', ls_default_data.letter_sent_status)

  localStorage.setItem(
    'mailman_cat_first_interaction_done',
    JSON.stringify(ls_default_data.mailman_cat_first_interaction_done)
  )

  localStorage.setItem('apples', JSON.stringify(ls_default_data.apples))
}

const initializeLocalStorageData = () => {
  getLetterSentStatus()
  getMailmanCatFirstInteractionDone()
  getAppleData(true)
}

const getLS = (key: LSKey) => {
  const val = localStorage.getItem(key)
  if (!val) return null

  try {
    const parsedVal = JSON.parse(val)

    if (parsedVal !== null) {
      return parsedVal
    }
  } catch (error) {
    return val
  }

  return val
}

const getLetterSentStatus = (): LetterSentStatusEnum => {
  const val = getLS('letter_sent_status')

  if (!Object.keys(LetterSentStatusEnum).includes(val)) {
    setDefaultValuesLS()
    return ls_default_data.letter_sent_status
  }

  return val
}

const getMailmanCatFirstInteractionDone = (): boolean => {
  const val = getLS('mailman_cat_first_interaction_done')

  if (val !== true && val !== false) {
    setDefaultValuesLS()
    return ls_default_data.mailman_cat_first_interaction_done
  }

  return val
}

const getAppleData = (full_check?: boolean): ApplesManagement => {
  sanitizeAppleData(full_check)
  return getLS('apples')
}

// If the apples object is tampered, set the default one.
const sanitizeAppleData = (full_check?: boolean) => {
  const apple_data: ApplesManagement | null = getLS('apples')
  const letter_status = getLetterSentStatus()
  const first_interaction_done = getMailmanCatFirstInteractionDone()

  if (!apple_data) {
    return setDefaultValuesLS()
  }

  if (
    typeof apple_data.total !== 'number' ||
    apple_data.total < 0 ||
    apple_data.total > total_apples_number
  ) {
    return setDefaultValuesLS()
  }

  if (
    typeof apple_data.collected !== 'number' ||
    apple_data.collected < 0 ||
    apple_data.collected > total_apples_number
  ) {
    return setDefaultValuesLS()
  }

  if (
    !apple_data.list ||
    typeof apple_data.list !== 'object' ||
    Array.isArray(apple_data.list) ||
    !Object.keys(apple_data.list).length ||
    Object.keys(apple_data.list).length !== Object.keys(ls_default_data.apples.list).length
  ) {
    return setDefaultValuesLS()
  }

  const apple_data_keys = Object.keys(apple_data.list)
  const default_apple_keys = Object.keys(ls_default_data.apples.list)

  if (!apple_data_keys.every((key) => default_apple_keys.includes(key))) {
    return setDefaultValuesLS()
  }

  if (!apple_data_keys.every((key) => typeof apple_data.list[key] === 'boolean')) {
    return setDefaultValuesLS()
  }

  const collectedApples = apple_data_keys.filter((key) => apple_data.list[key] === true)

  if (collectedApples.length !== apple_data.collected) {
    return setDefaultValuesLS()
  }

  // These filters should be checked only on init of the whole app. They'd trigger false positives if used
  // during the letter dialog, since we call getAppleData() in the just_paid_apples event listener of Forest.tsx
  if (full_check) {
    // If the letter has been sent succesfully, the apple data has to be the default one (all empty)
    if (letter_status === LetterSentStatusEnum.success) {
      if (
        apple_data.collected !== ls_default_data.apples.collected ||
        apple_data.total !== ls_default_data.apples.total ||
        !apple_data_keys.every((key) => apple_data.list[key] === ls_default_data.apples.list[key])
      ) {
        return setDefaultValuesLS()
      }
    }

    if (letter_status !== LetterSentStatusEnum.not_started && first_interaction_done !== true) {
      return setDefaultValuesLS()
    }
  }
}

export {
  initializeLocalStorageData,
  getLetterSentStatus,
  getAppleData,
  sanitizeAppleData,
  getMailmanCatFirstInteractionDone,
  ls_default_data,
}
