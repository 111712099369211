import { useEffect, useState, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { SceneChild } from '../../../types/portfolio_types'
import useFaceManager from '../../../hooks/FaceManager'
import { floorLoadedAtom } from '../../../store/store'
import { useAtom } from 'jotai'
import * as THREE from 'three'
import { deepDispose } from '../../../helpers/deepDispose'

const DepartmentStore = () => {
  const model = useGLTF('/models/visiting_worlds/department_store_scene.glb')
  const animations = useAnimations(model.animations, model.scene)
  const [sceneChildren, setSceneChildren] = useState<SceneChild[]>([])
  const [floorLoaded, setFloorLoaded] = useAtom(floorLoadedAtom)
  const scene_ref = useRef<THREE.Group>(null)

  const cashier_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)

  useFaceManager({
    id: 'cashier_cat',
    face_name: 'latte cat',
    default_face: 'latte cat neutral',
    face_material: cashier_cat_face_material_ref.current,
    min_filter: THREE.LinearFilter,
  })

  useEffect(() => {
    window.history.pushState({}, '', '/worlds/department-store')

    const processedSceneChildren = model.scene.children.map((child) => {
      return {
        object: child,
        element: <primitive object={child} key={child.id} />,
      }
    })

    setSceneChildren(processedSceneChildren)

    cashier_cat_face_material_ref.current = model.materials.cashier_cat_face as THREE.MeshStandardMaterial

    for (const materialKey of Object.keys(model.materials)) {
      const material = model.materials[materialKey] as THREE.MeshStandardMaterial

      if (material.map) {
        if (material.transparent && material.name.toLowerCase().includes('face')) {
          // Fix z-fighting
          material.polygonOffset = true
          material.polygonOffsetFactor = -1
        } else {
          material.map.minFilter = THREE.LinearFilter
          material.map.magFilter = THREE.LinearFilter
        }
      }
    }

    // PLAY ANIMATIONS
    Object.keys(animations.actions).forEach((animation_name) => animations.actions[animation_name]!.play())
  }, [model, animations])

  useEffect(() => {
    const children_not_in_scene = sceneChildren
      .map((sceneChild) => sceneChild.object)
      .filter((obj) => !model.scene.children.find((child) => child.id === obj.id))

    model.scene.children.push(...children_not_in_scene)
  }, [model, sceneChildren])

  useEffect(() => {
    if (scene_ref.current && !floorLoaded) {
      setFloorLoaded(true)
    }
  })

  // DISPOSE OBJECTS ON UNMOUNT
  useEffect(() => {
    return () => {
      if (!floorLoaded || !sceneChildren.length) return
      sceneChildren.forEach((child) => deepDispose(child.object))
    }
  }, [sceneChildren, floorLoaded])

  const renderScene = () => {
    return sceneChildren.map((child) => child.element)
  }

  return <group ref={scene_ref}>{renderScene()}</group>
}

export default DepartmentStore

// useGLTF.preload('/models/visiting_worlds/department_store_scene.glb')
