import { useEffect, useState, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { SceneChild } from '../../../types/portfolio_types'
import { floorLoadedAtom } from '../../../store/store'
import { useAtom } from 'jotai'
import * as THREE from 'three'
import { deepDispose } from '../../../helpers/deepDispose'

const TrainingMode = () => {
  const model = useGLTF('/models/visiting_worlds/arcade_stick.glb')
  const animations = useAnimations(model.animations, model.scene)
  const [sceneChildren, setSceneChildren] = useState<SceneChild[]>([])
  const [floorLoaded, setFloorLoaded] = useAtom(floorLoadedAtom)
  const scene_ref = useRef<THREE.Group>(null)

  useEffect(() => {
    window.history.pushState({}, '', '/worlds/training-mode')

    const processedSceneChildren = model.scene.children.map((child) => {
      return {
        object: child,
        element: <primitive object={child} key={child.id} />,
      }
    })

    setSceneChildren(processedSceneChildren)

    for (const materialKey of Object.keys(model.materials)) {
      const material = model.materials[materialKey] as THREE.MeshStandardMaterial

      if (material.map) {
        material.map.magFilter = THREE.LinearFilter

        if (materialKey === 'AS - Black noise parts 2') {
          material.map.minFilter = THREE.LinearMipMapNearestFilter
          continue
        }

        material.map.minFilter = THREE.LinearFilter
      }
    }

    // PLAY ANIMATIONS
    Object.keys(animations.actions).forEach((animation_name) => animations.actions[animation_name]!.play())
  }, [model, animations])

  useEffect(() => {
    const children_not_in_scene = sceneChildren
      .map((sceneChild) => sceneChild.object)
      .filter((obj) => !model.scene.children.find((child) => child.id === obj.id))

    model.scene.children.push(...children_not_in_scene)
  }, [model, sceneChildren])

  useEffect(() => {
    if (scene_ref.current && !floorLoaded) {
      setFloorLoaded(true)
    }
  })

  // DISPOSE OBJECTS ON UNMOUNT
  useEffect(() => {
    return () => {
      if (!floorLoaded || !sceneChildren.length) return
      sceneChildren.forEach((child) => deepDispose(child.object))
    }
  }, [sceneChildren, floorLoaded])

  const renderScene = () => {
    return sceneChildren.map((child) => child.element)
  }

  return (
    <group ref={scene_ref}>
      <directionalLight
        castShadow={false}
        position={[5, 12, 4]}
        intensity={1}
        color={'pink'}
        shadow-mapSize={[1024, 1024]}
        shadow-camera-near={1}
        shadow-camera-far={10}
        shadow-camera-top={10}
        shadow-camera-right={10}
        shadow-camera-bottom={-10}
        shadow-camera-left={-10}
      />
      {renderScene()}
    </group>
  )
}

export default TrainingMode

// useGLTF.preload('/models/visiting_worlds/arcade_stick.glb')
