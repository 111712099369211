import * as THREE from 'three'
import { Canvas, useThree } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useEffect, useState, useCallback, useRef } from 'react'
import styles from 'styles/components/BiancaCanvas.module.scss'
// import * as dat from 'lil-gui'
import useFaceManager from '../hooks/FaceManager'
import { BiancaAnimationName } from '../types/portfolio_types'

const { bianca_canvas } = styles

const BiancaInsideCanvas = () => {
  const bianca_model = useGLTF('/models/portfolio_forest/bianca.glb')
  const animations = useAnimations(bianca_model.animations, bianca_model.scene)
  // const [gui, setGui] = useState<dat.GUI | null>(null)
  const [currentAction, setCurrentAction] = useState<THREE.AnimationAction>()
  const bianca_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)

  useFaceManager({
    id: 'bianca_canvas',
    face_name: 'bianca',
    default_face: 'bianca neutral',
    face_material: bianca_face_material_ref.current,
    min_filter: THREE.LinearFilter,
  })

  const three = useThree()
  const camera = three.camera

  useEffect(() => {
    // When using OrbitControls, the position we set here won't take effect. Use OrbitControls to find your desired position,
    // set it here, and then comment out the controls.
    camera.position.set(1.65, 2.7, 0.55)
    camera.rotation.set(-0.12, 0, 0)

    bianca_face_material_ref.current = bianca_model.materials.bianca_face as THREE.MeshStandardMaterial

    // const newGui = new dat.GUI({ closeFolders: false })
    // const guiCameraFolder = newGui.addFolder('Camera')
    // guiCameraFolder.add(camera.position, 'x').min(-60).max(60).step(0.01).name('positionX').listen()
    // guiCameraFolder.add(camera.rotation, 'x').min(-60).max(60).step(0.01).name('rotationX').listen()
    // guiCameraFolder.add(camera.position, 'y').min(-60).max(60).step(0.01).name('positionY').listen()
    // guiCameraFolder.add(camera.position, 'z').min(-60).max(60).step(0.01).name('positionZ').listen()
    // setGui(newGui)
  }, [camera, bianca_model])

  const changeAction = useCallback(
    (animation_name: BiancaAnimationName, play_once?: boolean) => {
      const currentClip = currentAction?.getClip()

      if ((currentAction && currentClip && currentClip.name !== animation_name) || !currentAction) {
        const fadeDuration = 0.5
        currentAction?.fadeOut(fadeDuration)

        // It's necessary to always reset the animation when using fadeIn/fadeOut
        const animation = animations.actions[animation_name]!.reset().fadeIn(fadeDuration).play()
        if (play_once) animation.loop = THREE.LoopOnce
        animation.timeScale = 1

        setCurrentAction(animation)
      }
    },
    [currentAction, animations]
  )

  useEffect(() => {
    changeAction('bianca_idle')
  }, [changeAction])

  return (
    <>
      <directionalLight
        castShadow={false}
        position={[4, 4, 1]}
        intensity={2.5}
        shadow-mapSize={[1024, 1024]}
        shadow-camera-near={1}
        shadow-camera-far={10}
        shadow-camera-top={10}
        shadow-camera-right={10}
        shadow-camera-bottom={-10}
        shadow-camera-left={-10}
      />
      <ambientLight intensity={1.2} />

      {/* <OrbitControls panSpeed={0.7} /> */}
      <primitive object={bianca_model.scene}></primitive>
    </>
  )
}

const BiancaCanvas = () => {
  return (
    <Canvas
      className={bianca_canvas}
      gl={{ toneMapping: THREE.LinearToneMapping, antialias: true }}
      camera={{
        fov: 45,
        near: 0.1,
        far: 8,
        position: [0, 0, 0],
      }}
    >
      <BiancaInsideCanvas />
    </Canvas>
  )
}

export default BiancaCanvas

useGLTF.preload('/models/portfolio_forest/bianca.glb')
