const faces_dir: { [key: string]: string[] } = {
  bianca: ['confused', 'happy', 'naughty', 'neutral'],
  'standard cat': ['angry', 'bored', 'joyful', 'neutral'],
  'tena cat': ['bored'],
  'black cat': ['happy'],
  'landowner cat': ['neutral'],
  'latte cat': ['neutral'],
}

const blinking_variants = ['half-closed', 'closed']

export { faces_dir, blinking_variants }
