import { InteractableObject, ObjectInteractionMap, InteractionTypeEnum } from '../types/portfolio_types'
import * as THREE from 'three'

// NOTE: camera_position_offset is used in some objects that besides focusing (making the camera
// look at them), also change its position to end up with a better, more dramatic camera angle.
// It is determined manually by checking the camera position without any offset in the dat.gui, then
// changing it to the desired one. The offset to reach that desired number is camera_position_offset.
// We add it in Bianca.tsx's cameraFollowMe method.
const small_box_sensor: THREE.Vector3Tuple = [1.5, 1.5, 1]

const world_object_interactions: ObjectInteractionMap = {
  big_pine_tree_trunk001: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'check_project_trainingmode',
          text: 'A sign showing a videogame arcade controller rests on this tree. Should we go down the rabbit hole?',
          small_dialog: true,
          prompt_options: [
            { id: 'yes', text: 'Yes' },
            { id: 'no', text: 'No' },
          ],
        },
      ],
    },
  },
  big_pine_tree_trunk002: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'check_project_whoolso',
          text: 'A manatee logo appears on the sign of this tree. Should we go down the rabbit hole?',
          small_dialog: true,
          prompt_options: [
            { id: 'yes', text: 'Yes' },
            { id: 'no', text: 'No' },
          ],
        },
      ],
    },
  },
  big_pine_tree_trunk003: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'check_project_paperchat',
          text: 'The sign on the tree depicts a stylus drawing something. Should we go down the rabbit hole?',
          small_dialog: true,
          prompt_options: [
            { id: 'yes', text: 'Yes' },
            { id: 'no', text: 'No' },
          ],
        },
      ],
    },
  },
  cherry_blossom_tree_trunk001: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'check_job_ctworks',
          text: `There's a train on the sign attached to the tree. Should we go down the rabbit hole?`,
          small_dialog: true,
          prompt_options: [
            { id: 'yes', text: 'Yes' },
            { id: 'no', text: 'No' },
          ],
        },
      ],
    },
  },
  cherry_blossom_tree_trunk002: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'check_job_realestate',
          text: 'The sign on this tree contains a house and a GPS pin. Should we go down the rabbit hole?',
          small_dialog: true,
          prompt_options: [
            { id: 'yes', text: 'Yes' },
            { id: 'no', text: 'No' },
          ],
        },
      ],
    },
  },
  cherry_blossom_tree_trunk003: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.dialog,
      dialog: [
        {
          id: 'check_job_eci',
          text: 'A shopping cart appears on the sign belonging to this tree. Should we go down the rabbit hole?',
          small_dialog: true,
          prompt_options: [
            { id: 'yes', text: 'Yes' },
            { id: 'no', text: 'No' },
          ],
        },
      ],
    },
  },
  jump_spring_armature: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.animation,
    },
  },
  house_door: {
    inspect: {
      id: 'inspect',
      type: InteractionTypeEnum.animation,
    },
  },
}

const interactable_world_objects: { [key: string]: InteractableObject } = {
  big_pine_tree_trunk001: {
    id: 'big_pine_tree_trunk001',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 0, 3),
    sensor_size: small_box_sensor,
    on_inspect_animation: 'bianca_inspecting_rabbit_hole',
    rotate_in_place_when_facing: true,
    focus_on_contact: true,
    interactions: world_object_interactions.big_pine_tree_trunk001,
  },
  big_pine_tree_trunk002: {
    id: 'big_pine_tree_trunk002',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 0, 3),
    sensor_size: small_box_sensor,
    on_inspect_animation: 'bianca_inspecting_rabbit_hole',
    rotate_in_place_when_facing: true,
    focus_on_contact: true,
    interactions: world_object_interactions.big_pine_tree_trunk002,
  },
  big_pine_tree_trunk003: {
    id: 'big_pine_tree_trunk003',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 0, 3),
    sensor_size: small_box_sensor,
    on_inspect_animation: 'bianca_inspecting_rabbit_hole',
    rotate_in_place_when_facing: true,
    focus_on_contact: true,
    interactions: world_object_interactions.big_pine_tree_trunk003,
  },
  cherry_blossom_tree_trunk001: {
    id: 'cherry_blossom_tree_trunk001',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 0, 3),
    camera_position_offset: new THREE.Vector3(8, 13, 9),
    sensor_size: small_box_sensor,
    on_inspect_animation: 'bianca_inspecting_rabbit_hole',
    rotate_in_place_when_facing: true,
    focus_on_contact: true,
    interactions: world_object_interactions.cherry_blossom_tree_trunk001,
  },
  cherry_blossom_tree_trunk002: {
    id: 'cherry_blossom_tree_trunk002',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 0, 3),
    camera_position_offset: new THREE.Vector3(0, -11, 9),
    sensor_size: small_box_sensor,
    on_inspect_animation: 'bianca_inspecting_rabbit_hole',
    rotate_in_place_when_facing: true,
    focus_on_contact: true,
    interactions: world_object_interactions.cherry_blossom_tree_trunk002,
  },
  cherry_blossom_tree_trunk003: {
    id: 'cherry_blossom_tree_trunk003',
    inspect_word: 'Inspect',
    sensor_offset: new THREE.Vector3(0, 0, 3),
    camera_position_offset: new THREE.Vector3(-24, 4, 6),
    sensor_size: small_box_sensor,
    on_inspect_animation: 'bianca_inspecting_rabbit_hole',
    rotate_in_place_when_facing: true,
    focus_on_contact: true,
    interactions: world_object_interactions.cherry_blossom_tree_trunk003,
  },
  // Remember that objects with an armature get a position of [0, 0, 0],
  // it's their armature who holds the actual position
  jump_spring_armature: {
    id: 'jump_spring_armature',
    inspect_word: 'Jump',
    sensor_offset: new THREE.Vector3(0, 0, 0),
    sensor_size: [3, 1.5, 3],
    skip_setting_location: true,
    focus_on_contact: false,
    interactions: world_object_interactions.jump_spring_armature,
  },
  house_door: {
    id: 'house_door',
    inspect_word: 'Enter',
    sensor_offset: new THREE.Vector3(0, 0, 1),
    camera_position_offset: new THREE.Vector3(-1.5, 9, 40),
    sensor_size: small_box_sensor,
    skip_setting_location: true,
    focus_on_contact: true,
    interactions: world_object_interactions.house_door,
  },
}

export { interactable_world_objects }
