import { useAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import nipplejs from 'nipplejs'
import { JoystickManagerOptions, Joystick } from 'nipplejs'
import {
  activeMobileDirectionsAtom,
  currentInteractionAtom,
  currentJobProjectAtom,
  activeMobileSprintAtom,
} from '../store/store'
import { objectHasPropertiesValues } from '../helpers/helperFunctions'
import styles from 'styles/components/MobileControls.module.scss'
import { InteractionTypeEnum } from '../types/portfolio_types'

const {
  mobile_controls,
  arrows_area,
  triangle,
  left_triangle,
  right_triangle,
  up_triangle,
  down_triangle,
  running_button,
  running_button_bg,
  mobile_running_active,
} = styles

const activeDirOne = {
  mBackward: true,
  mLeftward: true,
  mRightward: false,
  mForward: false,
}

const activeDirTwo = {
  mBackward: true,
  mLeftward: false,
  mRightward: false,
  mForward: false,
}

const activeDirThree = {
  mBackward: true,
  mLeftward: false,
  mRightward: true,
  mForward: false,
}

const activeDirFour = {
  mBackward: false,
  mLeftward: true,
  mRightward: false,
  mForward: false,
}

const activeDirFive = {
  mBackward: false,
  mLeftward: false,
  mRightward: false,
  mForward: false,
}

const activeDirSix = {
  mBackward: false,
  mLeftward: false,
  mRightward: true,
  mForward: false,
}

const activeDirSeven = {
  mBackward: false,
  mLeftward: true,
  mRightward: false,
  mForward: true,
}

const activeDirEight = {
  mBackward: false,
  mLeftward: false,
  mRightward: false,
  mForward: true,
}

const activeDirNine = {
  mBackward: false,
  mLeftward: false,
  mRightward: true,
  mForward: true,
}

const JOYSTICK_COLOR = '#6b6b6b'

const MobileJoystick = () => {
  const joystick_ref = useRef<Joystick | null>(null)
  const [activeMobileDirectionsObj, setActiveMobileDirectionsObj] = useAtom(activeMobileDirectionsAtom)
  const [activeMobileSprint, setActiveMobileSprint] = useAtom(activeMobileSprintAtom)
  const [currentJobProject] = useAtom(currentJobProjectAtom)
  const [currentInteractionObj] = useAtom(currentInteractionAtom)

  const toggleMobileSprint = () => {
    setActiveMobileSprint(!activeMobileSprint)
  }

  useEffect(() => {
    let joystick = joystick_ref.current

    if (!joystick) {
      // 1) Create the options object for our joystick
      const options: JoystickManagerOptions = {
        zone: document.getElementById('joystick-canvas')!,
        mode: 'static',
        size: 100,
        color: JOYSTICK_COLOR,
        restOpacity: 0.7,
        dynamicPage: true,
        // Position the joystick in the middle of the canvas
        position: { left: '50%', top: '50%' },
      }

      // 2) Pass the options into the manager to create the joystick
      const manager = nipplejs.create(options)

      // 3) Get our joystick from the manager
      let joystick_n = 0

      while (!joystick_ref.current) {
        joystick_ref.current = manager.get(joystick_n)
        joystick_n++
      }

      joystick = joystick_ref.current
    }

    const resetPosition = () => {
      if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirFive)) {
        setActiveMobileDirectionsObj({ ...activeDirFive })
      }
    }

    const handleJoystickMove = (_event: nipplejs.EventData, data: nipplejs.JoystickOutputData) => {
      const X = joystick!.frontPosition.x
      const Y = joystick!.frontPosition.y

      // OUTPUT 1
      if (X >= -47 && X < -15 && Y >= 15 && Y <= 47) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirOne)) {
          setActiveMobileDirectionsObj({ ...activeDirOne })
        }
      }

      // OUTPUT 2
      if (X >= -15 && X < 28 && Y >= 47) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirTwo)) {
          setActiveMobileDirectionsObj({ ...activeDirTwo })
        }
      }

      // OUTPUT 3
      if (X >= 28 && X <= 47 && Y >= 10 && Y <= 47) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirThree)) {
          setActiveMobileDirectionsObj({ ...activeDirThree })
        }
      }

      // OUTPUT 6
      if (X > 47 && Y >= -10 && Y <= 10) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirSix)) {
          setActiveMobileDirectionsObj({ ...activeDirSix })
        }
      }

      // OUTPUT 9
      if (X >= 18 && X <= 48 && Y < -10 && Y >= -48) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirNine)) {
          setActiveMobileDirectionsObj({ ...activeDirNine })
        }
      }

      // OUTPUT 8
      if (X >= -15 && X <= 15 && Y < -47) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirEight)) {
          setActiveMobileDirectionsObj({ ...activeDirEight })
        }
      }

      // OUTPUT 7
      if (X >= -38 && X < -15 && Y < -10 && Y >= -45) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirSeven)) {
          setActiveMobileDirectionsObj({ ...activeDirSeven })
        }
      }

      // OUTPUT 4
      if (X <= -32 && Y >= -10 && Y <= 10) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirFour)) {
          setActiveMobileDirectionsObj({ ...activeDirFour })
        }
      }

      // CENTRAL RESET AREA - Data.distance returns how far the joystick is from the center using numbers from 1 to 50
      // 0 = total center (rest state), 50 = dragged to the furthermost edge. I consider 15 a good central point to work smoothly.
      if (data.distance <= 15) {
        if (!objectHasPropertiesValues(activeMobileDirectionsObj, activeDirFive)) {
          setActiveMobileDirectionsObj({ ...activeDirFive })
        }
      }
    }

    joystick.on('end', resetPosition)
    joystick.on('move', handleJoystickMove)

    return () => {
      joystick.off('end', resetPosition)
      joystick.off('end', handleJoystickMove)
    }
  }, [activeMobileDirectionsObj, setActiveMobileDirectionsObj])

  const getDeactivatedClass = () => {
    return currentJobProject ||
      (currentInteractionObj && currentInteractionObj.currentInteraction.type === InteractionTypeEnum.dialog)
      ? 'deactivated_hidden'
      : ''
  }

  return (
    <div className={`${mobile_controls} ${getDeactivatedClass()}`}>
      <div id="joystick-canvas">
        <div className={arrows_area}>
          <div className={`${triangle} ${left_triangle}`}></div>
          <div className={`${triangle} ${up_triangle}`}></div>
          <div className={`${triangle} ${right_triangle}`}></div>
          <div className={`${triangle} ${down_triangle}`}></div>
        </div>
      </div>

      <div
        className={`${running_button} ${activeMobileSprint ? mobile_running_active : ''}`}
        onClick={toggleMobileSprint}
      >
        <div className={running_button_bg}></div>
        <img src="/images/ui-icons/running.png" alt="running icon" />
      </div>
    </div>
  )
}

export default MobileJoystick
