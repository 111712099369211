import { useEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { SceneChild } from '../../../types/portfolio_types'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import { getRandomNumber } from '../../../helpers/helperFunctions'
import useFaceManager from '../../../hooks/FaceManager'
import { floorLoadedAtom } from '../../../store/store'
import { useAtom } from 'jotai'
import { deepDispose } from '../../../helpers/deepDispose'

interface NPCCatsDirection {
  [key: string]: {
    direction: 'left' | 'right' | 'rotating'
    speed: number
  }
}

const RealEstate = () => {
  const model = useGLTF('/models/visiting_worlds/real_estate_scene.glb')
  const animations = useAnimations(model.animations, model.scene)
  const [sceneChildren, setSceneChildren] = useState<SceneChild[]>([])
  const [floorLoaded, setFloorLoaded] = useAtom(floorLoadedAtom)
  const scene_ref = useRef<THREE.Group>(null)

  const tenant_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)
  const landlord_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)

  useFaceManager({
    id: 'tenant_cat',
    face_name: 'black cat',
    default_face: 'black cat happy',
    face_material: tenant_cat_face_material_ref.current,
    min_filter: THREE.NearestFilter,
  })

  useFaceManager({
    id: 'landlord_cat',
    face_name: 'landowner cat',
    default_face: 'landowner cat neutral',
    face_material: landlord_cat_face_material_ref.current,
    min_filter: THREE.NearestFilter,
  })

  const npcs_directions = useRef<NPCCatsDirection>({
    NPC_Cat_Rig001: { direction: 'left', speed: 0 },
    NPC_Cat_Rig002: { direction: 'left', speed: 0 },
    NPC_Cat_Rig003: { direction: 'right', speed: 0 },
    NPC_Cat_Rig004: { direction: 'right', speed: 0 },
    NPC_Cat_Rig005: { direction: 'right', speed: 0 },
    NPC_Cat_Rig006: { direction: 'right', speed: 0 },
    NPC_Cat_Rig007: { direction: 'right', speed: 0 },
    NPC_Cat_Rig008: { direction: 'left', speed: 0 },
    NPC_Cat_Rig009: { direction: 'left', speed: 0 },
  })
  const X_LEFT = -32
  const X_RIGHT = 40

  // const dirLightRef = useRef<THREE.DirectionalLight>(null)
  // useHelper(dirLightRef, THREE.DirectionalLightHelper, 1, 'red')

  useEffect(() => {
    window.history.pushState({}, '', '/worlds/real-estate')

    const processedSceneChildren = model.scene.children.map((child) => {
      return {
        object: child,
        element: <primitive object={child} key={child.id} />,
      }
    })

    setSceneChildren(processedSceneChildren)

    landlord_cat_face_material_ref.current = model.materials[
      'Landlord Cat Face'
    ] as THREE.MeshStandardMaterial

    tenant_cat_face_material_ref.current = model.materials['Tenant Cat Face'] as THREE.MeshStandardMaterial

    for (const materialKey of Object.keys(model.materials)) {
      const material = model.materials[materialKey] as THREE.MeshStandardMaterial

      if (material.map) {
        if (material.transparent && material.name.toLowerCase().includes('face')) {
          // Fix z-fighting
          material.polygonOffset = true
          material.polygonOffsetFactor = -1
        } else {
          material.map.minFilter = THREE.LinearFilter
          material.map.magFilter = THREE.LinearFilter
        }
      }
    }

    // PLAY ANIMATIONS
    Object.keys(animations.actions).forEach((animation_name) => {
      if (animation_name.startsWith('walk')) {
        animations.actions[animation_name]!.time = getRandomNumber(0.1, 0.7, true)
      }

      animations.actions[animation_name]!.play()
    })
  }, [model, animations])

  useEffect(() => {
    const children_not_in_scene = sceneChildren
      .map((sceneChild) => sceneChild.object)
      .filter((obj) => !model.scene.children.find((child) => child.id === obj.id))

    model.scene.children.push(...children_not_in_scene)
  }, [model, sceneChildren])

  useEffect(() => {
    if (scene_ref.current && !floorLoaded) {
      setFloorLoaded(true)
    }
  })

  // DISPOSE OBJECTS ON UNMOUNT
  useEffect(() => {
    return () => {
      if (!floorLoaded || !sceneChildren.length) return
      sceneChildren.forEach((child) => deepDispose(child.object))
    }
  }, [sceneChildren, floorLoaded])

  useFrame(() => {
    const npc_base_name = 'NPC_Cat_Rig00'
    const npcs = sceneChildren
      .filter((child) => child.object.name.includes(npc_base_name))
      .map((child) => child.object)

    for (const npc of npcs) {
      const npc_dir_data = npcs_directions.current[npc.name]
      const { direction, speed } = npc_dir_data
      let speed_to_use = speed

      if (!speed) {
        speed_to_use = getRandomNumber(0.015, 0.045, true)

        npcs_directions.current = {
          ...npcs_directions.current,
          [npc.name]: { ...npc_dir_data, speed: speed_to_use },
        }
      }

      if (direction === 'rotating') continue

      if (direction === 'left') {
        if (npc.position.x < X_LEFT) {
          npc.visible = false
          npc.rotation.y += Math.PI
          npc.position.x = X_LEFT
          const timeoutMs = getRandomNumber(400, 750)

          npcs_directions.current = {
            ...npcs_directions.current,
            [npc.name]: { ...npc_dir_data, direction: 'rotating' },
          }

          setTimeout(() => {
            npc.visible = true
            npcs_directions.current = {
              ...npcs_directions.current,
              [npc.name]: { direction: 'right', speed: getRandomNumber(0.015, 0.045, true) },
            }
          }, timeoutMs)

          return
        }

        npc.position.x -= speed_to_use
      }

      if (direction === 'right') {
        if (npc.position.x > X_RIGHT) {
          npc.visible = false
          npc.rotation.y += Math.PI
          npc.position.x = X_RIGHT
          const timeoutMs = getRandomNumber(400, 750)

          npcs_directions.current = {
            ...npcs_directions.current,
            [npc.name]: { ...npc_dir_data, direction: 'rotating' },
          }

          setTimeout(() => {
            npc.visible = true
            npcs_directions.current = {
              ...npcs_directions.current,
              [npc.name]: { direction: 'left', speed: getRandomNumber(0.015, 0.045, true) },
            }
          }, timeoutMs)

          return
        }

        npc.position.x += speed_to_use
      }
    }
  })

  const renderScene = () => {
    return sceneChildren.map((child) => child.element)
  }

  return (
    <group ref={scene_ref}>
      {renderScene()}

      <directionalLight
        // ref={dirLightRef}
        color={'#ffffff'}
        castShadow={false}
        position={[-7, 10, 10]}
        intensity={1.5}
        shadow-mapSize={[1024, 1024]}
        shadow-camera-near={1}
        shadow-camera-far={10}
        shadow-camera-top={10}
        shadow-camera-right={10}
        shadow-camera-bottom={-10}
        shadow-camera-left={-10}
      />
    </group>
  )
}

export default RealEstate

// useGLTF.preload('/models/visiting_worlds/real_estate_scene.glb')
