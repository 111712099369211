import { loadingAtom, loadingScreenClosedAtom } from '../store/store'
import { useAtom } from 'jotai'

const LOADING_SCREEN_ANIMATION_LENGTH = 1200

// This hook is meant to activate the loading screen and start
// loading what we actually want to load after the screen is fully showing
const useTriggerLoading = () => {
  const [, setIsLoading] = useAtom(loadingAtom)
  const [, setLoadingScreenClosed] = useAtom(loadingScreenClosedAtom)

  const triggerLoading = (loadingScreenSetCallback: () => void) => {
    setIsLoading(true)

    setTimeout(() => {
      loadingScreenSetCallback()
      setLoadingScreenClosed(true)
    }, LOADING_SCREEN_ANIMATION_LENGTH + 50)
  }

  return { triggerLoading }
}

export default useTriggerLoading
