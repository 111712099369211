import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyAIxPII5ncVqLsKDWF5ZYfUVY6U_rsh9l8',
  authDomain: 'dev-portfolio-2024.firebaseapp.com',
  projectId: 'dev-portfolio-2024',
  storageBucket: 'dev-portfolio-2024.appspot.com',
  messagingSenderId: '1066056443713',
  appId: '1:1066056443713:web:02e71657519f913693a3c0',
  measurementId: 'G-EESG968MN7',
}

const initFirebase = () => {
  initializeApp(firebaseConfig)
}

export { initFirebase }
