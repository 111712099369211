import { useEffect, useState, useCallback, useRef } from 'react'
import { isMobile, wait } from '../../helpers/helperFunctions'
import styles from 'styles/components/InteractUI.module.scss'
import { interactLetter } from '../../helpers/config'
import {
  availableInteractableObjectAtom,
  currentInteractionAtom,
  pickedApplesAtom,
  currentInteractionLocationAtom,
  insideHouseAtom,
  floorLoadedAtom,
  biancaBeingAnimatedAtom,
  animatingSlideAtom,
  writingLetterAtom,
  loadingAtom,
  showedKeyboardControlsHelpAtom,
  currentDialogItemAtom,
  gameStartedAtom,
} from '../../store/store'
import { useAtom } from 'jotai'
import { InteractionTypeEnum } from '../../types/portfolio_types'
import { interactable_bianca_objects } from '../../interactable_data/bianca_data'
import Dialog from './Dialog'
import emitter from '../../helpers/MittEmitter'
import useTriggerLoading from '../../hooks/TriggerLoading'
import { KEYBOARD_HELP_DURATION } from '../../helpers/config'

const {
  interact_ui,
  interact_btn_bg,
  interact_btn_body,
  interact_letter,
  inspect_is_active,
  gradient_text,
  shadow_text,
  top_info,
  quicklinks,
  quicklink,
  github_icon,
  apples_counter,
  apples_counter_text,

  keyboard_help,
  keyboard_help_exit_animation,
  keyboard_help_with_dialog_offset,
  keys,
  w,
  a,
  s,
  d,
  shift,
  keyboard_message_top,
  keyboard_message_bottom,
} = styles

const InteractUI = () => {
  const [interactableObject, setInteractableObject] = useAtom(availableInteractableObjectAtom)
  const [currentInteractionObj, setCurrentInteractionObj] = useAtom(currentInteractionAtom)
  const [, setCurrentInteractionLocation] = useAtom(currentInteractionLocationAtom)
  const [biancaBeingAnimated] = useAtom(biancaBeingAnimatedAtom)
  const [animatingSlide] = useAtom(animatingSlideAtom)
  const [loading] = useAtom(loadingAtom)
  const [gameStarted] = useAtom(gameStartedAtom)
  const [currentDialogItemObj] = useAtom(currentDialogItemAtom)
  const [showedKeyboardControlsHelp, setShowedKeyboardControlsHelp] = useAtom(showedKeyboardControlsHelpAtom)

  const [pickedApples] = useAtom(pickedApplesAtom)
  const [, setFloorLoaded] = useAtom(floorLoadedAtom)
  const [, setInsideHouse] = useAtom(insideHouseAtom)
  const [writingLetter] = useAtom(writingLetterAtom)
  const [inspectWord, setInspectWord] = useState('')
  const [showingKeyboardHelp, setShowingKeyboardHelp] = useState(false)
  const [keyboardHelpExiting, setKeyboardHelpExiting] = useState(false)

  const { triggerLoading } = useTriggerLoading()

  const keyboard_help_message = useRef<HTMLDivElement>(null)

  const handleInspectionStart = useCallback(() => {
    if (!interactableObject) return

    const interactionToPerformName = interactableObject.determineInteraction?.()

    const interactionToPerform = interactionToPerformName
      ? interactableObject.interactions[interactionToPerformName]
      : Object.values(interactableObject.interactions)[0]

    if (interactableObject.id === 'house_door') {
      return triggerLoading(() => {
        setInteractableObject(null)
        setFloorLoaded(false)
        setInsideHouse(true)
        emitter.emit('save_bianca_location', 'house')
      })
    }

    if (interactableObject.id === 'house_exit') {
      return triggerLoading(() => {
        setInteractableObject(null)
        setFloorLoaded(false)
        setInsideHouse(false)
      })
    }

    setCurrentInteractionObj({
      currentlyInspecting: interactableObject,
      currentInteraction: interactionToPerform,
    })

    if (interactableObject.position && !interactableObject.skip_setting_location) {
      setCurrentInteractionLocation({
        id: interactableObject.id,
        world_position: interactableObject.position,
      })
    }
  }, [
    interactableObject,
    setCurrentInteractionObj,
    triggerLoading,
    setInteractableObject,
    setFloorLoaded,
    setInsideHouse,
    setCurrentInteractionLocation,
  ])

  const talkToBianca = () => {
    if (
      loading ||
      !gameStarted ||
      interactableObject ||
      currentInteractionObj ||
      biancaBeingAnimated ||
      animatingSlide
    ) {
      return
    }

    const { bianca_rig } = interactable_bianca_objects

    const interactionToPerform = Object.values(bianca_rig.interactions)[0]

    setCurrentInteractionObj({
      currentlyInspecting: bianca_rig,
      currentInteraction: interactionToPerform,
    })
  }

  // If we don't save the inspect word, it will disappear immediately while
  // the inspect tag goes away in its animation.
  useEffect(() => {
    if (interactableObject?.inspect_word) {
      setInspectWord(interactableObject.inspect_word)
    }
  }, [interactableObject])

  useEffect(() => {
    const handleInteract = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === interactLetter && interactableObject && !currentInteractionObj) {
        handleInspectionStart()
      }
    }

    document.addEventListener('keyup', handleInteract)

    return () => {
      document.removeEventListener('keyup', handleInteract)
    }
  }, [interactableObject, handleInspectionStart, currentInteractionObj])

  useEffect(() => {
    if (showedKeyboardControlsHelp || loading || !gameStarted || isMobile()) return

    const showKeyboardHelp = async () => {
      await wait(2000)
      setShowedKeyboardControlsHelp(true)
      setShowingKeyboardHelp(true)

      await wait(KEYBOARD_HELP_DURATION)
      setKeyboardHelpExiting(true)

      await wait(500)
      setShowingKeyboardHelp(false)
    }

    showKeyboardHelp()
  }, [loading, showedKeyboardControlsHelp, setShowedKeyboardControlsHelp, gameStarted])

  const handleDialogEnd = () => {
    if (!currentInteractionObj) return
    const { currentInteraction, currentlyInspecting } = currentInteractionObj

    // When the interaction object won't set to null its own interaction location (e.g. as Bianca and the Mailman Cat do),
    // set it here. This is basically for the trees, without this we'd stay rotating towards them every time we inspect
    // a tree and don't go inside the rabbit hole
    if (!currentlyInspecting.self_managed_location) {
      setCurrentInteractionLocation(null)
    }

    if (currentInteraction.type === InteractionTypeEnum.dialog && currentInteraction.onFinish) {
      currentInteraction.onFinish()
    }

    setCurrentInteractionObj(null)
  }

  const getInteractButton = () => {
    const getInteractLetter = () => {
      if (isMobile()) return null
      return <span className={interact_letter}>({interactLetter})</span>
    }

    return (
      <div
        onClick={() => handleInspectionStart()}
        className={`${interact_btn_bg}
        ${interactableObject && !currentInteractionObj ? inspect_is_active : ''} 
        active_shrink`}
      >
        <div className={interact_btn_body}>
          {getInteractLetter()}
          {inspectWord}
        </div>
      </div>
    )
  }

  const getDialog = () => {
    if (!currentInteractionObj) return
    const { currentInteraction, currentlyInspecting } = currentInteractionObj

    if (currentInteraction.type === InteractionTypeEnum.dialog) {
      return (
        <Dialog
          inspecting_name={currentlyInspecting.name}
          interaction_item={currentInteraction}
          onDialogEnd={handleDialogEnd}
        />
      )
    }

    return null
  }

  const getApplesCount = () => {
    if (!pickedApples) return null

    return (
      <div className={apples_counter}>
        <img src="/images/apple.png" alt="apple" />

        <div className={apples_counter_text}>
          <div className={gradient_text}>
            {pickedApples.collected}/{pickedApples.total}
          </div>

          <div className={shadow_text}>
            {pickedApples.collected}/{pickedApples.total}
          </div>
        </div>
      </div>
    )
  }

  const getTalkToBiancaCSSClass = () => {
    if (interactableObject || currentInteractionObj || biancaBeingAnimated || animatingSlide) {
      return `${quicklink} deactivated_hidden`
    }

    return quicklink
  }

  const getHiddenLinkCSSClass = () => {
    if (writingLetter) return 'deactivated_hidden_large_tablet'
    return ''
  }

  const getKeyboardHelp = () => {
    if (!showingKeyboardHelp) return

    const getCSSClass = () => {
      let css_class = keyboard_help

      if (currentDialogItemObj && !currentDialogItemObj.small_dialog) {
        css_class += ` ${keyboard_help_with_dialog_offset}`
      }

      if (keyboardHelpExiting) {
        css_class += ` ${keyboard_help_exit_animation}`
      }

      return css_class
    }

    return (
      <div className={getCSSClass()} ref={keyboard_help_message}>
        <div className={keyboard_message_top}>Controls</div>
        <div className={keys}>
          <div className={w}>W</div>
          <div className={a}>A</div>
          <div className={s}>S</div>
          <div className={d}>D</div>
          <div className={shift}>Shift</div>
        </div>
        <div className={keyboard_message_bottom}>Hold shift to run</div>
      </div>
    )
  }

  return (
    <div className={`${interact_ui} ${loading || !gameStarted ? 'deactivated_hidden' : ''}`}>
      {getInteractButton()}
      {getDialog()}
      {getKeyboardHelp()}

      <div className={top_info}>
        {getApplesCount()}

        <div className={quicklinks}>
          <a
            className={`${quicklink} ${github_icon} ${getHiddenLinkCSSClass()}`}
            href="https://github.com/Lietsaki"
            target="_blank"
          >
            <img src="/images/ui-icons/github.png" alt="github" />
          </a>

          <a
            href="https://www.linkedin.com/in/ricardo-sandez"
            target="_blank"
            className={`${quicklink} ${getHiddenLinkCSSClass()}`}
          >
            <img src="/images/ui-icons/linkedin.png" alt="linkedin" />
          </a>

          <div className={getTalkToBiancaCSSClass()} onClick={talkToBianca} title="Talk to Bianca">
            <img src="/images/ui-icons/talk_bubble.png" alt="talk bubble" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InteractUI
