import { SceneChild } from '../types/portfolio_types'
import { useEffect, useState, useRef } from 'react'
import { CuboidCollider, CollisionPayload, RigidBody, RapierRigidBody, quat } from '@react-three/rapier'
import { useAtom } from 'jotai'
import {
  availableInteractableObjectAtom,
  currentInteractionAtom,
  playerLocationAtom,
  currentInteractionLocationAtom,
} from '../store/store'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import { determineYawToPointTo, shouldSpinYawClockwise } from '../helpers/helperFunctions'
import useFaceManager from '../hooks/FaceManager'

type MailmanCatAnimationName = 'mailman_cat_idle' | 'mailman_cat_happy' | 'mailman_cat_walk'

type MailmanCatProps = {
  mailman_cat_body: SceneChild
  mailman_cat_face: SceneChild
  mailman_cat_shirt: SceneChild
  mailman_cat_rig: SceneChild
  mailman_cat_animations: {
    mailman_cat_walk: THREE.AnimationAction
    mailman_cat_idle: THREE.AnimationAction
    mailman_cat_happy: THREE.AnimationAction
  }
}

const animation_speeds: { [key: string]: number } = {
  mailman_cat_walk: 1,
  mailman_cat_idle: 1,
  mailman_cat_happy: 1,
}

const MAILMAN_CAT_ID = 'mailman_cat_rig'
const DEFAULT_CAT_YAW = 335

const MailmanCat = ({
  mailman_cat_body,
  mailman_cat_face,
  mailman_cat_shirt,
  mailman_cat_rig,
  mailman_cat_animations,
}: MailmanCatProps) => {
  const [currentAction, setCurrentAction] = useState<THREE.AnimationAction>()
  const [, setInteractableObject] = useAtom(availableInteractableObjectAtom)
  const [currentInteractionObj] = useAtom(currentInteractionAtom)
  const [playerLocationObj] = useAtom(playerLocationAtom)
  const [currentInteractionLocationObj, setCurrentInteractionLocation] = useAtom(
    currentInteractionLocationAtom
  )
  const mailman_physics_body = useRef<RapierRigidBody>(null)
  const base_yaw = useRef<number | null>(DEFAULT_CAT_YAW)
  const target_yaw = useRef<number | null>(null)
  const spin_to_face_interact_clockwise = useRef(false)
  const mailman_cat_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)

  useFaceManager({
    id: 'mailman_cat',
    face_name: 'standard cat',
    default_face: 'standard cat neutral',
    face_material: mailman_cat_face_material_ref.current,
    min_filter: THREE.NearestFilter,
  })

  // SET FRUSTUM CULLING + SET MATERIAL FILTER + START IDLE ANIMATION
  useEffect(() => {
    // Meshes are rendered only if they are visible in the camera (this is basically what frustum culling means)
    // However, suddenly rendering these meshes causes a frame drop, it's better to always render them from the beginning
    mailman_cat_face.object.frustumCulled = false
    mailman_cat_body.object.frustumCulled = false
    mailman_cat_shirt.object.frustumCulled = false

    const mailman_cat_face_material = (mailman_cat_face.object as THREE.Mesh)
      .material as THREE.MeshStandardMaterial

    const mailman_cat_body_material = (mailman_cat_body.object as THREE.Mesh)
      .material as THREE.MeshStandardMaterial

    if (mailman_cat_face_material.map && mailman_cat_body_material.map) {
      mailman_cat_face_material.polygonOffset = true
      mailman_cat_face_material.polygonOffsetFactor = -1

      mailman_cat_body_material.map.minFilter = THREE.LinearFilter
      mailman_cat_body_material.map.magFilter = THREE.LinearFilter

      mailman_cat_face_material_ref.current = mailman_cat_face_material
    }

    if (!currentAction) {
      setCurrentAction(mailman_cat_animations.mailman_cat_idle!.play())
    }
  }, [
    mailman_cat_face,
    mailman_cat_body,
    mailman_cat_shirt,
    currentAction,
    mailman_cat_animations.mailman_cat_idle,
  ])

  useEffect(() => {
    if (!mailman_physics_body.current) return
    const current_yaw = getYawInDegrees(quat(mailman_physics_body.current.rotation()))

    if (current_yaw === 0) {
      target_yaw.current = DEFAULT_CAT_YAW
      spin_to_face_interact_clockwise.current = shouldSpinYawClockwise(current_yaw, target_yaw.current)
    }
  }, [])

  // If the mailman cat is the currently inspecting object, set the current interaction location
  // so that Bianca can turn towards us, and then turn to her afterwards
  useEffect(() => {
    if (!mailman_physics_body.current) return
    const mailman_position = mailman_physics_body.current.worldCom()
    const mailman_position_vector = new THREE.Vector3(
      mailman_position.x,
      mailman_position.y,
      mailman_position.z
    )

    if (currentInteractionObj?.currentlyInspecting.id === MAILMAN_CAT_ID && !currentInteractionLocationObj) {
      setCurrentInteractionLocation({
        id: MAILMAN_CAT_ID,
        world_position: mailman_position_vector,
      })
    }

    if (currentInteractionObj?.currentlyInspecting.id === MAILMAN_CAT_ID && playerLocationObj) {
      target_yaw.current = determineYawToPointTo(playerLocationObj.world_position, mailman_position_vector)

      spin_to_face_interact_clockwise.current = shouldSpinYawClockwise(
        getYawInDegrees(quat(mailman_physics_body.current.rotation())),
        target_yaw.current
      )

      return
    }

    if (!currentInteractionObj && currentInteractionLocationObj?.id === MAILMAN_CAT_ID) {
      target_yaw.current = base_yaw.current

      spin_to_face_interact_clockwise.current = shouldSpinYawClockwise(
        getYawInDegrees(quat(mailman_physics_body.current.rotation())),
        target_yaw.current!
      )

      setCurrentInteractionLocation(null)
    }
  }, [currentInteractionObj, playerLocationObj, currentInteractionLocationObj, setCurrentInteractionLocation])

  useFrame((_, delta) => {
    if (!mailman_physics_body.current) return
    const target_yaw_satisfied = targetYawSatisfied()

    if (target_yaw.current && !target_yaw_satisfied) {
      changeAction('mailman_cat_walk')
      mailman_physics_body.current.setEnabledRotations(false, true, false, true)

      // spin_direction is higher than in Bianca due to differences in the RigidBody
      // that end up making the mailman cat heavier
      const spin_y = 7000 * delta
      const spin_y_val = spin_to_face_interact_clockwise.current ? -spin_y : spin_y

      mailman_physics_body.current.applyTorqueImpulse(new THREE.Vector3(0, spin_y_val, 0), true)
    } else if (currentInteractionObj?.currentlyInspecting.id === MAILMAN_CAT_ID) {
      changeAction('mailman_cat_happy')
    }

    if (
      !currentInteractionObj &&
      currentAction &&
      currentAction.getClip().name !== 'mailman_cat_idle' &&
      target_yaw_satisfied
    ) {
      changeAction('mailman_cat_idle')
    }
  })

  const getYawInDegrees = (quaternion: THREE.Quaternion) => {
    const euler = new THREE.Euler().setFromQuaternion(quaternion, 'YXZ')
    let yaw = THREE.MathUtils.radToDeg(euler.y)

    if (yaw < 0) yaw += 360
    return yaw
  }

  const changeAction = (animation_name: MailmanCatAnimationName) => {
    const current_clip = currentAction?.getClip()

    if (currentAction && current_clip && current_clip.name !== animation_name) {
      const fadeDuration = 0.5
      currentAction.fadeOut(fadeDuration)

      const animation = mailman_cat_animations[animation_name]!.reset().fadeIn(fadeDuration).play()
      animation.timeScale = animation_speeds[animation_name] || 1

      setCurrentAction(animation)
    }
  }

  const targetYawSatisfied = () => {
    if (!mailman_physics_body.current || target_yaw.current === null) return true

    const t_yaw = target_yaw.current
    const current_yaw = getYawInDegrees(quat(mailman_physics_body.current.rotation()))
    const offset = 4
    const target_yaw_low = t_yaw - offset < 0 ? t_yaw - offset + 360 : t_yaw - offset
    const target_yaw_high = t_yaw - offset > 359 ? t_yaw + offset - 360 : t_yaw + offset

    const wrap_around = target_yaw_low > target_yaw_high

    if (
      (!wrap_around && current_yaw > target_yaw_low && current_yaw < target_yaw_high) ||
      (wrap_around && (current_yaw >= target_yaw_low || current_yaw <= target_yaw_high))
    ) {
      mailman_physics_body.current.setEnabledRotations(false, false, false, true)
      target_yaw.current = null
      return true
    }

    return false
  }

  const addInteractableObject = (e: CollisionPayload) => {
    if (!mailman_cat_rig.interactable || e.colliderObject?.name !== 'bianca') return
    setInteractableObject(mailman_cat_rig.interactable)
  }

  const removeInteractableObject = (e: CollisionPayload) => {
    if (e.colliderObject?.name !== 'bianca') return
    setInteractableObject(null)
  }

  return (
    <RigidBody
      name="mailman_cat_rig_body"
      ref={mailman_physics_body}
      key={mailman_cat_body.object.id}
      colliders="cuboid"
      type="dynamic"
      angularDamping={3}
      enabledRotations={[false, true, false]}
      lockTranslations
    >
      {mailman_cat_body.element}
      {mailman_cat_face.element}
      {mailman_cat_shirt.element}
      {mailman_cat_rig.element}

      <CuboidCollider
        name="mailman_cat_rig_interaction_area"
        sensor
        onIntersectionEnter={addInteractableObject}
        onIntersectionExit={removeInteractableObject}
        args={mailman_cat_rig.interactable!.sensor_size}
        rotation={mailman_cat_rig.object.rotation}
        position={mailman_cat_rig.object.position}
      />
    </RigidBody>
  )
}

export default MailmanCat
