import { useTexture } from '@react-three/drei'
import { useEffect, useRef, useMemo, useCallback } from 'react'
import * as THREE from 'three'
import { faces_dir, blinking_variants } from '../interactable_data/faces_dir'
import { wait, getRandomNumber } from '../helpers/helperFunctions'
import { useAtom } from 'jotai'
import { currentDialogItemAtom } from '../store/store'

interface FaceManagerProps {
  id: string
  face_name: string
  default_face: string
  face_material: THREE.MeshStandardMaterial | null
  min_filter: THREE.MinificationTextureFilter
}

const useFaceManager = ({ id, face_name, default_face, face_material, min_filter }: FaceManagerProps) => {
  const current_base_face = useRef(default_face)
  const should_blink = useRef(true)
  const blinking_id = useRef(0)

  const [currentDialogItemObj] = useAtom(currentDialogItemAtom)

  const texturePathsObject = useMemo(() => {
    return faces_dir[face_name].reduce((obj: { [key: string]: string }, expression_name: string) => {
      const base_name = `${face_name} ${expression_name}`
      obj[base_name] = `/faces/${face_name}/${base_name}.png`

      for (const variant of blinking_variants) {
        const variant_name = `${base_name} ${variant}`
        obj[variant_name] = `/faces/${face_name}/${variant_name}.png`
      }

      return obj
    }, {})
  }, [face_name])

  const textures = useTexture(texturePathsObject)

  const setFace = useCallback(
    (texture_name: string) => {
      const texture = textures[texture_name]
      if (!texture || !face_material) return

      texture.colorSpace = THREE.SRGBColorSpace
      texture.flipY = false
      texture.needsUpdate = true
      texture.minFilter = min_filter
      texture.name = texture_name

      face_material.map = texture
    },
    [face_material, textures, min_filter]
  )

  const changeCurrentFace = useCallback(
    (texture_name: string) => {
      setFace(texture_name)
      current_base_face.current = texture_name
    },
    [setFace]
  )

  const startBlinkingForCurrentFace = useCallback(() => {
    if (!current_base_face.current) return
    const HALF_CLOSED_INTERVAL = 50
    const BLINKING_ID = blinking_id.current

    const stopBlinking = () => {
      if (BLINKING_ID !== blinking_id.current || !should_blink.current) {
        return true
      }

      return false
    }

    const performBlink = async () => {
      const BASE_INTERVAL = getRandomNumber(1000, 2500)
      await wait(BASE_INTERVAL)

      if (stopBlinking()) return
      setFace(current_base_face.current + ' half-closed')
      await wait(HALF_CLOSED_INTERVAL)

      if (stopBlinking()) return
      setFace(current_base_face.current + ' closed')
      await wait(HALF_CLOSED_INTERVAL)

      if (stopBlinking()) return
      setFace(current_base_face.current + ' half-closed')
      await wait(HALF_CLOSED_INTERVAL)

      if (stopBlinking()) return
      setFace(current_base_face.current)

      if (!stopBlinking()) await performBlink()
    }

    performBlink()
  }, [setFace])

  useEffect(() => {
    should_blink.current = true
    blinking_id.current++
    startBlinkingForCurrentFace()

    return () => {
      changeCurrentFace(default_face)
      should_blink.current = false
    }
  }, [startBlinkingForCurrentFace, default_face, setFace, changeCurrentFace])

  // Change current face if the current dialog indicates it. The blinking never stops.
  useEffect(() => {
    if (
      (!currentDialogItemObj || !currentDialogItemObj.included_expressions) &&
      current_base_face.current !== default_face
    ) {
      return changeCurrentFace(default_face)
    }

    if (
      currentDialogItemObj?.included_expressions &&
      currentDialogItemObj.included_expressions[id] &&
      textures[currentDialogItemObj.included_expressions[id]]
    ) {
      changeCurrentFace(currentDialogItemObj.included_expressions[id])
    }
  }, [currentDialogItemObj, default_face, changeCurrentFace, id, textures])

  return { changeCurrentFace, current_base_face }
}

export default useFaceManager
