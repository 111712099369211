import { Object3D, Quaternion, Vector3, Vector3Tuple } from 'three'

export type BiancaAnimationName =
  | 'bianca_walk'
  | 'bianca_run'
  | 'bianca_looking_around'
  | 'bianca_idle'
  | 'bianca_preparing_jump'
  | 'bianca_mid_jump'
  | 'bianca_inspecting_rabbit_hole'
  | 'bianca_sliding_down'

export interface SceneChild {
  element: React.JSX.Element
  object: Object3D
  interactable?: InteractableObject
}

// Position is added in the scene where the interactable object is rendered
// Mainly so that we can use setCurrentInteractionLocation in InteractUI with it,
// and to focus on interactable objects in Bianca's cameraFollowMe
export interface InteractableObject {
  id: string
  name?: string
  position?: Vector3
  on_inspect_animation?: BiancaAnimationName
  skip_setting_location?: boolean
  self_managed_location?: boolean
  rotate_in_place_when_facing?: boolean
  camera_position_offset?: Vector3
  determineInteraction?: () => string
  focus_on_contact: boolean
  inspect_word: string
  sensor_offset: Vector3
  sensor_size: Vector3Tuple
  interactions: InteractionsObject
}

export enum InteractionTypeEnum {
  dialog = 'dialog',
  animation = 'animation',
}

export enum LetterSentStatusEnum {
  success = 'success',
  error = 'error',
  not_started = 'not_started',
}

export enum VisitingWorldNameEnum {
  trainingmode = 'trainingmode',
  paperchat = 'paperchat',
  whoolso = 'whoolso',
  train_workshop = 'train_workshop',
  real_estate = 'real_estate',
  department_store = 'department_store',
}

export interface ObjectInteractionMap {
  [object_name: string]: InteractionsObject
}

interface InteractionsObject {
  [interaction_name: string]: InteractionObject
}

export type InteractionObject = InteractionItemDialog | InteractionItemAnimation

export interface InteractionItemAnimation {
  id: string
  type: InteractionTypeEnum.animation
}

export interface InteractionItemDialog {
  id: string
  dialog: DialogItem[]
  type: InteractionTypeEnum.dialog
  show_name?: boolean
  focus_on_start?: boolean
  onFinish?: () => void
}

export interface DialogItem {
  id: string
  text: string
  prompt_options?: PromptOption[]
  onFinish?: () => void
  determineBranch?: () => string
  backToPreviousBranchOnFinish?: boolean
  branches?: {
    [route_name: string]: DialogItem[]
  }
  included_expressions?: {
    [id: string]: string
  }
  small_dialog?: boolean
}

export interface PromptOption {
  id: string
  text: string
  determineBranch?: () => string
  branches?: {
    [route_name: string]: DialogItem[]
  }
}

export interface ApplesManagement {
  total: number
  collected: number
  list: {
    [apple_name: string]: boolean
  }
}

export interface CurrentInteractionData {
  currentlyInspecting: InteractableObject
  currentInteraction: InteractionObject
}

export interface ObjectLocation {
  id: string
  world_position: Vector3
}

export interface JustPickedApplesMap {
  [apple_name: string]: boolean
}

export type JobProjectType = 'job' | 'personal project'
export type ProjectPlatform = 'webapp' | 'api' | 'npm' | 'android'

export type JobProjectLink = {
  id: string
  platform_type: ProjectPlatform
  url: string
  private: boolean
  published: boolean
}

export interface JobProject {
  id: string
  name: string
  world: VisitingWorldNameEnum
  type: JobProjectType
  release_date?: string
  job_start?: string
  job_end?: string
  video_name: string
  video_title: string
  icon_name: string

  controls_min_distance?: number
  controls_max_distance?: number
  controls_bbox_size?: number
  controls_camera_position?: Vector3Tuple
  controls_camera_position_mobile?: Vector3Tuple

  default_exit_location?: BiancaLocation

  github_link?: string
  links: JobProjectLink[]

  dialog: InteractionItemDialog
}

export interface BiancaLocation {
  position: Vector3
  rotation: Quaternion
}

export interface ActiveMobileDirections {
  mForward: boolean
  mLeftward: boolean
  mBackward: boolean
  mRightward: boolean
  [key: string]: boolean
}

export type PositionToSave = 'house' | 'world'
