/* eslint-disable @typescript-eslint/no-unused-vars */
// import { useThree } from '@react-three/fiber'
import { useEffect, useState } from 'react'
import { Stats } from '@react-three/drei'
import * as dat from 'lil-gui'

const DebugGui = () => {
  // const { camera } = useThree()
  const [gui, setGui] = useState<dat.GUI | null>(null)
  // const controlsRef = useRef(null)

  // const setCameraDebugGui = () => {
  //   if (gui) gui.destroy()

  //   const newGui = new dat.GUI({ closeFolders: false })
  //   const guiCameraFolder = newGui.addFolder('Camera')

  //   guiCameraFolder.add(camera.position, 'x').min(-60).max(60).step(0.01).name('positionX').listen()
  //   guiCameraFolder.add(camera.position, 'y').min(-60).max(60).step(0.01).name('positionY').listen()
  //   guiCameraFolder.add(camera.position, 'z').min(-60).max(60).step(0.01).name('positionZ').listen()

  //   // OrbitControls takes hold of the camera and do not allow to change the rotation programatically.
  //   // Comment the orbit controls and this will work, but it's more useful to keep it, at least that way we can
  //   // see the rotation values change when manipulated by the orbit controls.
  //   // NOTE: For rotation, use PI as the range values, since PI represents half of a rotation in a circle, however, to tweak the camera
  //   // I need smaller values, so I'm going with 0.5
  //   // guiCameraFolder.add(camera.rotation, 'x').min(-Math.PI).max(Math.PI).step(0.1).name('rotationX').listen()
  //   guiCameraFolder.add(camera.rotation, 'x').min(-0.5).max(0.5).step(0.01).name('rotationX').listen()
  //   guiCameraFolder.add(camera.rotation, 'y').min(-0.5).max(0.5).step(0.01).name('rotationY').listen()
  //   guiCameraFolder.add(camera.rotation, 'z').min(-0.5).max(0.5).step(0.01).name('rotationZ').listen()
  //   setGui(newGui)
  // }

  useEffect(() => {
    // setCameraDebugGui()

    return () => {
      if (gui) {
        gui.destroy()
        setGui(null)
      }
    }
  }, [gui])

  // OrbitControls takes control of the camera and won't let it move following Bianca
  return (
    <>
      <Stats showPanel={0} className="stats" />
      {/* <OrbitControls ref={controlsRef} makeDefault /> */}
    </>
  )
}

export default DebugGui
